import React, { useState, useContext, useEffect } from "react";
import { Grid } from "@material-ui/core";
import MyContext from "../../../Provider/MyContext";
import { H3, AvenirBlackH4, H5 } from "../../../utils/text";
import {
  CirclePluse as CirclePluseIcon,
  InfoOutlined,
} from "../../../components/SVG";
import { validateAlphaNumeric } from "../../../utils/util";
import {
  ModifierMinMaxSelect,
  CustomCheckbox,
  ModifierItem,
  RightDrawerTitleButtons,
  InputText,
  DraggableList,
} from "../../../components";
import { useModifierGroup } from "../../../custom-hooks";

const ModifierGroupCreateEdit = ({
  open,
  initialValues = null,
  handleCreate = () => {},
  handleEdit = () => {},
  handleDelete = () => {},
  onClose,
}) => {
  const {
    modifierGroup,
    isEditing,
    errors,
    updateModifierGroup,
    updateAddonItem,
    addAddonItem,
    removeAddonItem,
    validateModifierGroup,
  } = useModifierGroup(initialValues);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const Context = useContext(MyContext);
  const handleCreateModifier = () => {
    if (validateModifierGroup()) handleCreate(modifierGroup);
  };
  const handleEditModifier = () => {
    if (validateModifierGroup()) handleEdit(modifierGroup);
  };
  const handleDeleteModifier = (e) => {
    if (e) {
      handleDelete(modifierGroup);
    } else {
      setShowDeleteDialog(false);
    }
  };

  const handleAlertCloseOpen = (value) => {
    setShowDeleteDialog(value);
  };
  const handleDragEnd = (startIndex, endIndex) => {
    const reorderedItems = Array.from(modifierGroup?.addon_items);
    const [removed] = reorderedItems.splice(startIndex, 1);
    reorderedItems.splice(endIndex, 0, removed);
    const finalReorder = reorderedItems.map((item, index) => ({
      ...item,
      position: index,
    }));
    updateModifierGroup("addon_items", finalReorder);
  };
  useEffect(() => {
    if (
      modifierGroup.addon_items?.length >= 1 &&
      modifierGroup.maximum_select > modifierGroup.addon_items?.length
    ) {
      updateModifierGroup("maximum_select", modifierGroup.addon_items?.length);
    }
  }, [modifierGroup.addon_items]);

  const renderItem = (item, index) => (
    <ModifierItem
      key={index}
      addonItemName={item.addon_item_name}
      basePrice={item.base_price}
      handleDeleteItem={() => removeAddonItem(index)}
      onItemNameChange={(value) =>
        updateAddonItem(index, "addon_item_name", value)
      }
      onPriceChange={(value) => updateAddonItem(index, "base_price", value)}
      errorModifierName={errors[`addon_item_name_${index}`]}
      errorBasePrice={errors[`base_price_${index}`]}
    />
  );
  return (
    <RightDrawerTitleButtons
      open={open}
      onClose={onClose}
      isEditing={isEditing}
      title={
        isEditing
          ? Context.langData.edit_modifier_group
          : Context.langData.create_modifier_group
      }
      alertOpenClose={showDeleteDialog}
      handleCreate={handleCreateModifier}
      handleEdit={handleEditModifier}
      handleDelete={handleDeleteModifier}
      handleAlertCloseOpen={handleAlertCloseOpen}>
      <Grid
        style={{
          marginLeft: 56,
          marginRight: 56,
          width: 500,
        }}>
        <Grid
          container
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: 20,
            borderBottom: "1px solid #EEEEEE",
          }}>
          <H3
            data-qaid='createaddon_valuelabel'
            label={Context.langData.modifier_details}
            style={{ marginBottom: 10 }}
          />
        </Grid>
        <Grid style={{ paddingTop: 20 }}>
          <Grid container style={{ display: "flex", flexDirection: "column" }}>
            <AvenirBlackH4
              data-qaid='createaddon_valuelabel'
              label={Context.langData.modifier_group_name}
            />
            <InputText
              value={modifierGroup?.addon_cat_name}
              error={errors.addon_cat_name}
              onChange={(val) =>
                updateModifierGroup(
                  "addon_cat_name",
                  validateAlphaNumeric(val.target.value)
                )
              }
              maxLength={64}
              id='addon_cat_name'
            />
          </Grid>
          <Grid item container alignItems='center'>
            <CustomCheckbox
              checked={modifierGroup?.is_mandatory}
              onChange={(e) => {
                updateModifierGroup("is_mandatory", e.target.checked);
              }}
              id='is_mandatory'
            />
            <AvenirBlackH4
              label='Mandatory modifier'
              style={{ marginRight: 20 }}
            />
          </Grid>
          <ModifierMinMaxSelect
            id='allow_multi_select'
            checked={modifierGroup?.allow_multi_select}
            checkBoxLabel={Context.langData.allow_multi_select}
            supportingTextLabel={
              Context.langData.allow_more_than_one_item_selection
            }
            initialCountOfMin={modifierGroup.minimum_select}
            initialCountOFmax={
              modifierGroup.minimum_select >= modifierGroup.maximum_select
                ? modifierGroup.minimum_select
                : modifierGroup.maximum_select
            }
            minOfMin={modifierGroup.is_mandatory ? 1 : 0}
            maxOfMin={modifierGroup?.addon_items?.length || 1}
            minOfMax={modifierGroup.minimum_select}
            maxOfMax={modifierGroup?.addon_items?.length}
            multiSelectMax={modifierGroup?.addon_items?.length}
            isMandatory
            onCheckboxChange={(value) =>
              updateModifierGroup("allow_multi_select", value)
            }
            onMinChange={(value) =>
              updateModifierGroup("minimum_select", value)
            }
            onMaxChange={(value) =>
              updateModifierGroup("maximum_select", value)
            }
          />
          <ModifierMinMaxSelect
            checked={modifierGroup?.allow_multi_quantity}
            checkBoxLabel={Context.langData.allow_multi_quantity}
            supportingTextLabel={
              Context.langData.allow_more_than_one_item_quantity
            }
            initialCountOfMin={modifierGroup.minimum_quantity || 1}
            initialCountOFmax={
              modifierGroup.minimum_quantity >= modifierGroup.maximum_quantity
                ? modifierGroup.minimum_quantity
                : modifierGroup.maximum_quantity
            }
            minOfMin={1}
            maxOfMin={
              modifierGroup.maximum_quantity > 1
                ? modifierGroup.maximum_quantity
                : 100
            }
            minOfMax={modifierGroup.minimum_quantity}
            maxOfMax={100}
            isMandatory={false}
            onCheckboxChange={(value) =>
              updateModifierGroup("allow_multi_quantity", value)
            }
            onMinChange={(value) =>
              updateModifierGroup("minimum_quantity", value)
            }
            onMaxChange={(value) =>
              updateModifierGroup("maximum_quantity", value)
            }
          />
          <Grid
            container
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 20,
              borderBottom: "1px solid #EEEEEE",
            }}>
            <H3
              data-qaid='createaddon_valuelabel'
              label={Context.langData.modifier_items}
              style={{ marginBottom: 10 }}
            />
          </Grid>
          <Grid
            container
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 20,
            }}>
            {modifierGroup?.addon_items && (
              <DraggableList
                items={modifierGroup?.addon_items}
                onDragEnd={handleDragEnd}
                renderItem={renderItem}
                isEditing
                gridItemProps={{
                  display: "flex",
                  flexDirection: "column",
                }}
                position='position'
              />
            )}
            {errors.addon_items && (
              <Grid
                style={{
                  color: "#AB1212",
                  display: "flex",
                  justifyContent: "center",
                  marginLeft: 0,
                  marginTop: 8,
                }}>
                <InfoOutlined
                  width={16}
                  height={16}
                  color='#AB1212'
                  style={{ marginRight: 6 }}
                />
                <H5 label={errors.addon_items} style={{ color: "#AB1212 " }} />
              </Grid>
            )}
            <Grid
              container
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: 15,
                cursor: "pointer",
                width: "100%",
                justifyContent: "center",
                padding: "20px 0px",
              }}
              onClick={addAddonItem}>
              <CirclePluseIcon
                data-qaid='createaddon-CirclePluseIcon'
                color='#007191'
                style={{ marginRight: 10 }}
              />
              <AvenirBlackH4
                data-qaid='createaddon__add'
                label={Context.langData.add_modifier_item}
                style={{ color: "#007191" }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </RightDrawerTitleButtons>
  );
};

export default ModifierGroupCreateEdit;
