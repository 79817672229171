import React from "react";
import {
  Select,
  Checkbox,
  MenuItem,
  FormControl,
  makeStyles,
  withStyles,
  Chip,
  Tooltip,
} from "@material-ui/core";
import _ from "lodash";
import clsx from "clsx";
import { H4, DotText } from "../utils/text";
import { DownVector } from "./SVG";

const MultiSelectChip = ({
  list,
  selectedValue = [],
  handleChange,
  value,
  name,
  placeholder = "",
  border = false,
  minWidth,
  height,
  width,
  tooltipMessage = "",
  disableMultiChip = false,
  ...props
}) => {
  const useStyles = makeStyles(() => ({
    formControl: {
      minWidth: 500,
    },
    root: {
      "& .MuiFilledInput-root": {
        backgroundColor: "#FFF",
        "&.Mui-disabled": {
          backgroundColor: "#FAFAFA",
          borderRadius: 5,
          border: "1px solid #C3C3C3",
        },
      },
    },
    selectRoot: {
      "&:focus": {
        backgroundColor: "#FFF",
        borderRadius: 5,
        border: "2px solid #004C60",
      },
      "&.Mui-disabled": {
        backgroundColor: "#FAFAFA",
        borderRadius: 5,
      },
    },
    chip: {
      margin: 2,
    },
    chipsContainer: {
      display: "flex",
      flexWrap: "wrap",
      maxHeight: 100,
      overflowY: "auto",
    },
    selectContainer: {
      border: "1px solid #707272",
      borderRadius: 5,
      backgroundColor: "#FFFFFF",
      fontSize: 14,
      fontFamily: "AvenirLT",
      minHeight: height,
      width,
      boxSizing: "border-box",
      "&.Mui-disabled": {
        backgroundColor: "#FAFAFA",
        borderRadius: 5,
        border: "1px solid #C3C3C3",
      },
    },
    customToolTip: {
      backgroundColor: "#051D33",
      color: "#FFF",
      fontSize: "14px",
      fontWeight: 300,
      fontFamily: "AvenirLT",
    },
    customArrow: {
      color: "#051D33",
    },
    customCheckbox: {
      "&.MuiCheckbox-colorSecondary.Mui-checked": {
        color: "#051D33",
      },
    },
  }));

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const menuProps = {
    PaperProps: {
      style: {
        marginTop: 8,
        backgroundColor: "#FFFFFF",
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    },
    getContentAnchorEl: null,
  };

  const changeValue = (e) => {
    handleChange(e.target.value);
  };

  const handleDelete = (chipToDelete) => (event) => {
    event.stopPropagation();
    event.preventDefault();
    handleChange(selectedValue.filter((value) => value !== chipToDelete));
  };

  const selectedRender = (selected) => {
    if (selected.length === 0) {
      return <H4 label={placeholder} />;
    }
    return (
      <div className={classes.chipsContainer}>
        {selected.map((s) => {
          const item = _.find(list, (li) => li[value] === s);
          if (item) {
            return (
              <Chip
                key={s}
                label={item[name]}
                onDelete={handleDelete(s)}
                className={classes.chip}
                onMouseDown={(event) => event.stopPropagation()}
                style={{
                  backgroundColor: "#FFF",
                  border: "1px solid #C3C3C3",
                  borderRadius: 5,
                  paddingRight: 5,
                }}
              />
            );
          }
          return null;
        })}
      </div>
    );
  };

  const classes = useStyles({ border });
  const iconStyles = {
    selectIcon: {
      marginTop: 8,
    },
  };
  const CustomExpandVector = withStyles(iconStyles)(
    ({ className, classes, ...rest }) => (
      <DownVector {...rest} className={clsx(className, classes.selectIcon)} />
    )
  );

  return (
    <FormControl
      data-qaid='multiselect_form_control'
      variant={border ? "outlined" : "filled"}
      style={{
        minWidth,
        background: "#FFF",
        margin: 2,
        borderRadius: 5,
        "&:focus": {
          color: "3px solid #004C60",
        },
      }}>
      <Tooltip
        title={disableMultiChip && tooltipMessage ? tooltipMessage : ""}
        disableHoverListener={!disableMultiChip}
        arrow
        classes={{ tooltip: classes.customToolTip, arrow: classes.customArrow }}
        placement='bottom-end'>
        <Select
          data-qaid='multiselect_select'
          labelId='demo-multiple-checkbox-label'
          id='demo-multiple-checkbox'
          multiple
          displayEmpty
          className={classes.selectContainer}
          IconComponent={CustomExpandVector}
          classes={{ root: classes.root, select: classes.selectRoot }}
          style={{ borderRadius: 6 }}
          disableUnderline
          value={selectedValue}
          onChange={changeValue}
          renderValue={selectedRender || placeholder}
          MenuProps={menuProps}
          disabled={disableMultiChip}
          {...props}>
          {list.map((item, index) => (
            <MenuItem
              data-qaid='multiselect_menuitem'
              key={index}
              value={item[value]}
              classes={classes.root}
              style={{ maxWidth: width }}>
              <Checkbox
                data-qaid='multiselect_checkbox'
                classes={{ root: classes.customCheckbox }}
                checked={selectedValue.indexOf(item[value]) > -1}
              />
              <DotText
                data-qaid='multiselect_label_text'
                style={{ color: "#051D33" }}
                label={item[name]}
              />
            </MenuItem>
          ))}
        </Select>
      </Tooltip>
    </FormControl>
  );
};

export default React.memo(MultiSelectChip);
