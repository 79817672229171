import moment from "moment";

const chennels = [
  { id: 1, type: "in_restaurant" },
  { id: 2, type: "online" },
  { id: 3, type: "third_party" },
];
const deliveryModes = [
  { id: 1, type: "dinein" },
  { id: 2, type: "takeaway" },
  { id: 3, type: "delivery" },
];
const dateFormat = (d) => {
  if (d) {
    const date = new Date(d);
    const MMM = date.toLocaleString("en-us", { month: "short" });
    const da = date.getDate();
    const year = date.getFullYear();
    return `${MMM} ${da}, ${year}`;
  }
  return "NA";
};
const getDate = (year, month, day) => `${year}-${month}-${day}`;
const getToday = () => {
  const date = new Date();
  const da = date.getDate();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  return `${year}-${month}-${da}`;
};
const getTodayMMDDYYYY = () => {
  const date = new Date();
  const da = date.getDate();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  return `${month}/${da}/${year}`;
};
const convertNormalCase = (str = "") => {
  const strs = str.split(" ");
  return strs
    .map((s, index) => {
      const st =
        index === 0
          ? s[0].toUpperCase() + s.slice(1).toLowerCase()
          : s[0].toLowerCase() + s.slice(1).toLowerCase();
      return st;
    })
    .join(" ");
};
const ConvertPrice = (value) => {
  if (value) {
    if (typeof value === "string") value = value.split(",").join("");
  }
  let PriceValue = 0;
  if (value && value !== undefined) {
    PriceValue = value;
  }
  const cp = Number.parseFloat(PriceValue)
    .toFixed(2)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  return cp;
};
const ConvertToCurrencyPrice = (value, currency) => ({
  amount: value,
  currency,
});

const ConvertCurrencyPriceToDollar = (value) => {
  let PriceValue = 0.0;

  if (value) {
    PriceValue = value.amount / 100.0;
  }

  return PriceValue;
};

const ConvertDateToUTCFormat = (d) => {
  if (d) {
    const date = new Date(`${d} 00:00:00`);
    const postDate = date.toISOString();
    return postDate;
  }
  return "NA";
};

const parseToFloat = (value) => {
  if (value) {
    if (typeof value === "string") value = value.split(",").join("");
  } else {
    value = "0";
  }
  return parseFloat(value);
};
const setPostalCode = (postalCode) => {
  let value = "";
  if (postalCode) {
    value = `${postalCode.substring(0, 3)} ${postalCode.substring(3)}`;
  }
  return value;
};
const setPhoneNumber = (phone) => {
  let value = null;
  if (phone) {
    value = `${phone.substring(0, 1)}-${phone.substring(
      1,
      4
    )}-${phone.substring(4, 7)}-${phone.substring(7)}`;
  }
  return value;
};
const convertPhone = (phone = "") => {
  phone = phone.replace(/[^\d]/g, "");
  if (phone.length === 10) {
    return phone.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
  }
  return null;
};
const orderTypefilter = (ordertypes, channel, deliveryMode) => {
  const ch = chennels.find((c) => c.type === channel);
  const mode = deliveryModes.find((d) => d.type === deliveryMode);
  if (ch && mode) {
    return ordertypes.filter(
      (o) => o.order_channel_id === ch.id && o.delivery_mode_id === mode.id
    );
  }
  return [];
};

const handleHoursMinutes = (value) => {
  const splitValues = value?.split(":");
  const hoursAndMinutes = splitValues?.slice(0, 2)?.join(":");
  return hoursAndMinutes;
};

const ProvinceData = [
  { name: "Alberta", value: "Alberta" },
  { name: "British Columbia", value: "British Columbia" },
  { name: "Manitoba", value: "Manitoba" },
  { name: "New Brunswick", value: "New Brunswick" },
  { name: "Newfoundland and Labrador", value: "Newfoundland and Labrador" },
  { name: "Northwest Territories", value: "Northwest Territories" },
  { name: "Nova Scotia", value: "Nova Scotia" },
  { name: "Nunavut", value: "Nunavut" },
  { name: "Ontario", value: "Ontario" },
  { name: "Prince Edward Island", value: "Prince Edward Island" },
  { name: "Quebec", value: "Quebec" },
  { name: "Saskatchewan", value: "Saskatchewan" },
  { name: "Yukon", value: "Yukon" },
];
const PurchaseOrderStatus = () => ({
  RAISED: "Raised",
  IN_PROGRESS: "In Progress",
  FULFILLED: "Fulfilled",
  CLOSED: "Closed",
  ALL: "all",
});
const getDateFormat = (timestamp) => {
  const dateTime = new Date(timestamp);
  return moment(dateTime).format("yyyyMMDDHHmmss");
};

const getDateTimeFormat = (timestamp) => {
  const dateTime = new Date(timestamp);
  return moment(dateTime).format("MMMM DD, YYYY hh:mm A");
};

const validateText = (s) => {
  const regex = /[^a-zA-Z\s\u00C0-\u00FF]/g;
  const value = s?.replace(regex, "");
  return value;
};

const validateAlphaNumeric = (s) => {
  const regex = /[^a-zA-Z0-9\s\u00C0-\u00FF]/g;
  const value = s?.replace(regex, "");
  return value;
};

const validateNumber = (s) => {
  const regex = /[^0-9]/g;
  const value = s?.replace(regex, "");
  return value;
};
const debounce = (func, delay) => {
  let timeoutId;
  // eslint-disable-next-line func-names
  return function (...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
};

// Price validations
const validatePositiveFloatValue = (inputValue) => {
  if (/^\d*\.?\d{0,2}$/.test(inputValue)) {
    const returnValue = parseFloat(inputValue);
    if (inputValue === "" || returnValue >= 0) {
      return returnValue;
    }
    return "";
  }
  return "";
};

function replaceWithDynamicText(template, replacements) {
  return template.replace(/{(\w+)}/g, (_, key) => {
    const value = replacements[key];
    return value || value === 0 ? value : "";
  });
}

export {
  ConvertPrice,
  ConvertToCurrencyPrice,
  ConvertCurrencyPriceToDollar,
  ConvertDateToUTCFormat,
  convertPhone,
  orderTypefilter,
  ProvinceData,
  PurchaseOrderStatus,
  convertNormalCase,
  setPostalCode,
  dateFormat,
  setPhoneNumber,
  getToday,
  getTodayMMDDYYYY,
  getDate,
  parseToFloat,
  handleHoursMinutes,
  getDateTimeFormat,
  getDateFormat,
  validateText,
  validateAlphaNumeric,
  validateNumber,
  debounce,
  validatePositiveFloatValue,
  replaceWithDynamicText,
};
