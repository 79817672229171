import * as React from "react";

const DisableDelete = ({
  width = 24,
  height = 24,
  color = "#C3C3C3",
  ...props
}) => (
  <svg
    {...props}
    width={width}
    height={height}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.571 1.47a.245.245 0 00-.244.244V2.94h7.347V1.714a.245.245 0 00-.245-.245H8.57zm8.572 1.469V1.714A1.715 1.715 0 0015.429 0H8.57a1.714 1.714 0 00-1.714 1.714V2.94H.735a.735.735 0 100 1.47h2.204v17.877A1.714 1.714 0 004.653 24h14.694a1.714 1.714 0 001.714-1.714V4.408h2.204a.735.735 0 100-1.47h-6.122zM4.408 4.409v17.877a.245.245 0 00.245.245h14.694a.245.245 0 00.245-.245V4.408H4.408zm3.184 1.958c.406 0 .735.33.735.735v11.755a.735.735 0 11-1.47 0V7.102c0-.406.33-.735.735-.735zm4.408 0c.406 0 .735.33.735.735v11.755a.735.735 0 11-1.47 0V7.102c0-.406.33-.735.735-.735zm4.408 0c.406 0 .735.33.735.735v11.755a.735.735 0 01-1.47 0V7.102c0-.406.33-.735.735-.735z'
      fill={color}
    />
  </svg>
);

export default DisableDelete;
