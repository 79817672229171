import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid } from "@material-ui/core";
import _ from "lodash";
import DataTable from "../../../../components/DataTable";
import styles from "../../../../styles/index";
import {
  deleteKitchens,
  fetchKitchenName,
  fetchKitchenPrinterUnassigned,
} from "../../../../redux/actions";
import AlertDialog from "../../../../components/Dialog";
import CirclePluse from "../../../../components/SVG/PluseIcon";
import MyContext from "../../../../Provider/MyContext";
import { AvenirBlackH4 } from "../../../../utils/text";
import UploadIcon from "../../../../components/SVG/UploadIcon";
import DeleteIcon from "../../../../components/SVG/Delete";
import SnackBar from "../../../../components/SnackBar";
import NoData from "../../../../components/NoData";
import ButtonMenu from "../../../../components/ButtonMenu";
import CreateKitchen from "./CreateKitchen";
import EditKitchen from "./EditKitchen";
import AssignMenuDrawer from "./AssignMenuDrawer";

const KitchenList = () => {
  const style = styles();
  const Context = useContext(MyContext);
  const [columns, setColumns] = useState([
    {
      field: "name",
      headerName: Context.langData.kitchen,
      size: "50%",
      sort: false,
    },
    {
      field: "description",
      headerName: Context.langData.description,
      size: "50%",
      sort: false,
    },
  ]);

  const ButtonMenuData = [
    {
      id: 1,
      icon: UploadIcon,
      name: Context.langData.assign_kitchen,
    },
    {
      id: 2,
      icon: DeleteIcon,
      name: Context.langData.delete,
    },
  ];
  const [selectedId, setSelectedId] = React.useState([]);
  const [btnDisable, setBtnDisable] = React.useState(true);
  const [openCreate, toggleCreateDrawer] = useState(false);
  const [openEdit, toggleEditDrawer] = useState(false);
  const [assignMenu, toggleAssignMenuDrawer] = useState(false);
  const [row, setRow] = useState({});
  const Kitchens = useSelector((state) => state.metaReducer.kitchenName);
  const categories = useSelector((state) => state.menu.categories);
  const dispatch = useDispatch();

  const [showDialog, setShowDialog] = useState(false);
  const [snackbar, setSnackbar] = useState(null);
  const [KitchensList, setKitchensList] = useState([]);

  const bodyMessage =
    selectedId.length > 1
      ? `Are you sure you want to delete ${selectedId.length} kitchens?`
      : Context.langData.delete_kitchen_body;
  const heading =
    selectedId.length > 1
      ? `${Context.langData.delete_kitchen}s`
      : Context.langData.delete_kitchen;
  useEffect(() => {
    const filterKitchenData = _.reverse(Kitchens);
    setKitchensList(filterKitchenData);
  }, [Kitchens]);

  const onCheckedIds = (ids) => {
    setSelectedId(ids);
    if (ids.length > 0) {
      setBtnDisable(false);
    } else {
      setBtnDisable(true);
    }
  };
  const onClickAction = (rowValue) => {
    setRow(rowValue);
    toggleEditDrawer(true);
  };
  const btnProps = {};
  if (btnDisable) {
    btnProps.disabled = true;
  }
  const commanCallback = () => {
    toggleCreateDrawer(false);
    toggleEditDrawer(false);
    toggleAssignMenuDrawer(false);
    dispatch(fetchKitchenName());
    dispatch(fetchKitchenPrinterUnassigned());
  };
  const createCallback = (isSuccess) => {
    if (isSuccess) {
      setSnackbar({
        message: Context.langData.kitchen_create_succ,
        severity: "success",
      });
    } else {
      setSnackbar({
        message: Context.langData.kitchen_create_failed,
        severity: "error",
      });
    }
    commanCallback();
  };
  const editCallback = (isSuccess) => {
    if (isSuccess) {
      setSnackbar({
        message: Context.langData.kitchen_edit_succ,
        severity: "success",
      });
    } else {
      setSnackbar({
        message: Context.langData.kitchen_edit_failed,
        severity: "error",
      });
    }
    commanCallback();
  };
  const deleteCallback = (isSuccess) => {
    if (isSuccess) {
      setSnackbar({
        message:
          selectedId.length > 1
            ? Context.langData.kitchens_delete_succ
            : Context.langData.kitchen_delete_succ,
        severity: "success",
      });
    } else {
      setSnackbar({
        message: Context.langData.kitchen_delete_failed,
        severity: "error",
      });
    }
    commanCallback();
  };

  const assignCallback = (isSuccess, count) => {
    if (isSuccess) {
      setSnackbar({
        message:
          count > 1
            ? Context.langData.kitchens_assign_succ
            : Context.langData.kitchen_assign_succ,
        severity: "success",
      });
    } else {
      setSnackbar({
        message: Context.langData.kitchen_assign_failed,
        severity: "error",
      });
    }
    commanCallback();
  };
  const handleClose = (val) => {
    if (val) {
      dispatch(deleteKitchens(JSON.stringify(selectedId), deleteCallback));
    }
    setShowDialog(false);
  };
  function deletePrinter(value) {
    if (selectedId && selectedId.length > 0) {
      setShowDialog(value);
    }
  }

  const onPageinationChange = () => {
    console.log("on page change");
  };
  const callBack = (id) => {
    if (id === 1) {
      toggleAssignMenuDrawer(true);
    } else if (id === 2) {
      deletePrinter(true);
    }
  };

  const sortingOnClick = (value, sortValue, index) => {
    const columnData = [...columns];
    columnData[index] = { ...columnData[index], sort: !sortValue };
    setColumns(columnData);
    const sortKitchens = _.orderBy(
      Kitchens,
      [value],
      [sortValue ? "asc" : "desc"]
    );
    setKitchensList(sortKitchens);
  };
  return (
    <div data-qaid='kitchenList_div'>
      {KitchensList.length > 0 ? (
        <Grid
          data-qaid='kitchenList_grid'
          container
          direction='row'
          style={{ padding: 0 }}>
          <Grid
            data-qaid='kitchenList_buttongrid'
            item
            xs={12}
            sm={10}
            lg={12}
            style={{ marginTop: 24 }}>
            <Grid
              data-qaid='kitchenList_add grid'
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
                marginRight: 20,
                marginBottom: 20,
              }}>
              <ButtonMenu
                style={{ marginLeft: 10 }}
                menuItems={ButtonMenuData}
                label={Context.langData.edit_kitchen}
                callBack={callBack}
                btnDisable={btnDisable}
              />
              <Button
                className={style.buttonClass}
                data-qaid='kitchenList_addbutton'
                style={{
                  backgroundColor: "#FFB600",
                  color: "white",
                  marginRight: 10,
                }}
                variant='contained'
                id='cancel'
                onClick={() => toggleCreateDrawer(true)}>
                <CirclePluse data-qaid='kitchenList_circleicon' />
                <AvenirBlackH4
                  data-qaid='kitchenList_createlabel'
                  label={Context.langData.create_kitchen}
                  style={{ paddingLeft: 5 }}
                />
              </Button>
            </Grid>
            {KitchensList.length > 0 ? (
              <DataTable
                data-qaid='kitchenList_datatable'
                columns={columns}
                rows={KitchensList}
                onCheckedIds={onCheckedIds}
                onClickAction={onClickAction}
                onPageChange={onPageinationChange}
                sortingOnClick={sortingOnClick}
                refresh={openCreate || assignMenu}
              />
            ) : (
              <div>
                <AvenirBlackH4
                  data-qaid='kitchenList_nodata'
                  label={Context.langData.no_data}
                />
              </div>
            )}
          </Grid>
        </Grid>
      ) : (
        <NoData page='kitchen' callback={() => toggleCreateDrawer(true)} />
      )}
      {openCreate && (
        <CreateKitchen
          data-qaid='kitchenList_createoffer'
          open={openCreate}
          onClose={() => toggleCreateDrawer(false)}
          kitchen={KitchensList}
          callback={createCallback}
        />
      )}
      {openEdit && (
        <EditKitchen
          data-qaid='kitchenList_editoffer'
          open={openEdit}
          row={row}
          kitchen={KitchensList}
          onClose={() => toggleEditDrawer(false)}
          callback={editCallback}
        />
      )}
      {assignMenu && (
        <AssignMenuDrawer
          open={assignMenu}
          row={row}
          KitchensList={KitchensList}
          categories={categories}
          onClose={() => toggleAssignMenuDrawer(false)}
          callback={assignCallback}
        />
      )}
      {snackbar && (
        <SnackBar
          data-qaid='taxeslist_snackbar'
          open
          setOpen={() => setSnackbar(null)}
          severity={snackbar.severity}
          message={snackbar.message}
        />
      )}

      <AlertDialog
        data-qaid='kitchenList_alertdialog'
        showDialog={showDialog}
        body={bodyMessage}
        heading={heading}
        IsError={false}
        handleClose={handleClose}
      />
    </div>
  );
};

export default KitchenList;
