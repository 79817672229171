import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Grid } from "@material-ui/core";

const DraggableList = ({
  items,
  onDragEnd,
  renderItem,
  isEditing,
  gridItemProps,
  position = "item_id",
}) => {
  const handleDragEnd = (result) => {
    if (!result.destination) return;
    onDragEnd(result.source.index, result.destination.index);
  };
  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "#EEEEEE" : "#FFFFFF",
  });

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    display: "flex",
    flexDirection: "row",
    background: isDragging ? "#EEEEEE" : "#FFFFFF",
    ...draggableStyle,
  });

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId='droppable' direction='vertical'>
        {(provided, snapshot) => (
          <Grid
            container
            spacing={2}
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={{
              ...getListStyle(snapshot.isDraggingOver),
              ...gridItemProps,
            }}>
            {items.map((item, index) => (
              <Draggable
                key={index}
                draggableId={item[position]?.toString()}
                index={index}>
                {(provided) => (
                  <Grid
                    item
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                    {...gridItemProps}>
                    {renderItem(item, index, isEditing)}
                  </Grid>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </Grid>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DraggableList;
