import React from "react";
import {
  FormControl,
  OutlinedInput,
  FormHelperText,
  makeStyles,
} from "@material-ui/core";
import styles from "../styles";
import { AvenirBlackH4 } from "../utils/text";
import { ExclamationIcon } from "./SVG";
import HelperText from "./HelperText";

// eslint-disable-next-line no-unused-vars
const useOutlinedInputStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
    "& $notchedOutline": {
      borderColor: "#707272",
    },
    "&:hover $notchedOutline": {
      borderColor: "#707272",
    },
    "&$focused $notchedOutline": {
      borderColor: "#707272",
    },
    "&.Mui-error": {
      borderColor: "#AB1212",
      marginLeft: 0,
    },
  },
  focused: {},
  notchedOutline: {},
}));
const InputNumber = ({
  value,
  info = "",
  onChange,
  id,
  error,
  placeholder = "",
  inputPlaceholder = "",
  type = "text",
  isPlaceHolder = true,
  height = 56,
  ...props
}) => {
  const style = styles();
  const outlinedInputClasses = useOutlinedInputStyles();
  if (error) {
    props.error = true;
  }
  return (
    <FormControl
      data-qaid='inputtext_formcontrol'
      {...props}
      variant='outlined'
      className={style.fullWidth}>
      {isPlaceHolder && (
        <AvenirBlackH4
          data-qaid='inputtext_placeholder'
          label={placeholder}
          style={{ marginBottom: 2 }}
        />
      )}
      <OutlinedInput
        data-qaid='inputtext_outlinedinput'
        variant='standard'
        {...props}
        id={id}
        type={type}
        value={value}
        placeholder={inputPlaceholder || isPlaceHolder}
        onChange={onChange}
        inputMode='numeric'
        inputProps={{ type }}
        aria-describedby={`${id}-text`}
        classes={outlinedInputClasses}
        style={{ height: height !== "null" ? height : 50 }}
      />
      {error ? (
        <FormHelperText
          data-qaid='inputtext_formhelpertext'
          id={`${id}-text`}
          style={{
            color: "#AB1212",
            display: "flex",
            margin: "8px 0px 0px 0px",
          }}>
          <ExclamationIcon color='#AB1212' style={{ marginRight: 10 }} />
          {error}
        </FormHelperText>
      ) : (
        info && <HelperText text={info} />
      )}
    </FormControl>
  );
};
export default InputNumber;
