import React from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  IconButton,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { H2, H4, AvenirBlackH4, AvenirWhiteH4 } from "../utils/text";
import { CloseCross } from "./SVG";

const useStyles = makeStyles(() => ({
  dialogPaper: {
    padding: "20px",
    maxWidth: "400px",
    minHeight: "226px",
    minWidth: "400px",
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  titleText: {
    fontWeight: 600,
    fontSize: "18px",
  },
  iconContainer: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  bodyText: {
    margin: "10px 0 20px 0",
    fontSize: "14px",
    color: "#666",
  },
  successIcon: {
    color: "#4CAF50",
  },
  errorIcon: {
    color: "#f44336",
  },
}));

const AlertDialog = ({
  open,
  onClose,
  title,
  bodyText,
  primaryButtonText,
  secondaryButtonText,
  onPrimaryAction,
  onSecondaryAction,
  children = null,
  primaryButtonType,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{ paper: classes.dialogPaper }}>
      <DialogContent style={{ padding: 0 }}>
        <Grid container className={classes.titleContainer}>
          <Grid item className={classes.iconContainer}>
            <H2 data-qaid='dialog_heading_label' label={title} />
          </Grid>
          <IconButton onClick={onClose}>
            <CloseCross />
          </IconButton>
        </Grid>
        <div className={classes.bodyText}>
          {children || <H4 label={bodyText} />}
        </div>
      </DialogContent>
      <DialogActions>
        {secondaryButtonText && (
          <Button
            variant='contained'
            onClick={onSecondaryAction || onClose}
            color='default'
            style={{
              height: 40,
              borderRadius: 5,
              backgroundColor: "#FFF",
              border: "2px solid #051D33",
              width: "50%",
            }}>
            <AvenirBlackH4 label={secondaryButtonText} />
          </Button>
        )}
        {primaryButtonType === "single" && (
          <Button
            onClick={onPrimaryAction}
            variant='contained'
            color='default'
            style={{
              height: 40,
              borderRadius: 5,
              backgroundColor: "#FFB600",
              width: "100%",
            }}>
            <AvenirBlackH4
              data-qaid='dialog_delete_label'
              label={primaryButtonText}
            />
          </Button>
        )}

        {primaryButtonText &&
          (primaryButtonType === "Delete" ? (
            <Button
              onClick={onPrimaryAction}
              variant='contained'
              color='default'
              style={{
                height: 40,
                borderRadius: 5,
                backgroundColor: "#AB1212",
                width: "50%",
              }}>
              <AvenirWhiteH4
                data-qaid='dialog_delete_label'
                label={primaryButtonText}
              />
            </Button>
          ) : (
            primaryButtonType !== "single" && (
              <Button
                onClick={onPrimaryAction}
                variant='contained'
                style={{
                  height: 40,
                  borderRadius: 5,
                  backgroundColor: "#FFB600",
                  width: "50%",
                }}>
                <AvenirBlackH4
                  data-qaid='dialog_delete_label'
                  label={primaryButtonText}
                />
              </Button>
            )
          ))}
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
