import React, { useState, useEffect, useContext } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
  Divider,
} from "@material-ui/core";
import {
  AvenirBlackH2,
  H4,
  AvenirBlackH3,
  DotText,
  AvenirBlackH4,
} from "../utils/text";
import MyContext from "../Provider/MyContext";
import styles from "../styles/index";
import RightPointingArrow from "./SVG/RightPointingArrow";
import { DownVector, UpVector, CircleClose } from "./SVG";
import CustomCheckbox from "./CustomCheckbox";
import SingleSelect from "./SingleSelect";

const useStyles = makeStyles(() => ({
  root: {
    width: "calc(100% - 40px)",
    margin: "0 20px",
    overflowX: "auto",
    position: "relative",
  },
  table: {
    minWidth: 650,
    borderCollapse: "separate",
    borderSpacing: "0 8px",
  },
  headerRow: {
    backgroundColor: "#FFFFFF",
    "& th": {
      fontWeight: "bold",
      border: "none",
      padding: "12px 8px",
    },
  },
  dataRow: {
    cursor: "pointer",
    backgroundColor: "#FAFAFA",
    "& td": {
      border: "none",
      padding: "12px 8px",
    },
  },
  cell: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth: 150,
    width: "33.33%",
  },
  scrollableTableBody: {
    maxHeight: "40vh",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "8px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#004C60",
      borderRadius: "4px",
    },
  },
  stickyHeader: {
    position: "sticky",
    top: 0,
    zIndex: 1,
    backgroundColor: "#FFFFFF",
    padding: "15px",
  },
}));

const ReassignTaxProfileModal = ({
  open,
  onClose,
  categoriesData,
  taxProfiles,
  currentProfileName,
  onSave,
}) => {
  const classes = useStyles();
  const style = styles();
  const [categories, setCategories] = useState([]);
  const [standaloneItems, setStandaloneItems] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const [allSelectValue, setAllSelectValue] = useState("");
  const [expandedRows, setExpandedRows] = useState({});
  const Context = useContext(MyContext);

  useEffect(() => {
    if (categoriesData) {
      const modifiedCategories = categoriesData?.categories?.map(
        (category) => ({
          ...category,
          checked: false,
          tax_profile_id: "",
          selectValue: "",
          items: category.items.map((item) => ({
            ...item,
            checked: false,
            tax_profile_id: "",
            selectValue: "",
          })),
        })
      );

      const modifiedItems = categoriesData?.items?.map((item) => ({
        ...item,
        checked: false,
        tax_profile_id: "",
        selectValue: "",
      }));
      setCategories(modifiedCategories);
      setStandaloneItems(modifiedItems);
    }
  }, [categoriesData]);

  const handleAllCheckboxChange = (checked) => {
    setAllChecked(checked);
    setAllSelectValue((prev) => (checked ? prev : ""));
    setCategories((prevCategories) =>
      prevCategories.map((category) => ({
        ...category,
        checked,
        selectValue: checked ? allSelectValue : "",
        items: category.items.map((item) => ({
          ...item,
          checked,
          selectValue: checked ? allSelectValue : "",
        })),
      }))
    );

    setStandaloneItems((prevItems) =>
      prevItems.map((item) => ({
        ...item,
        checked,
        selectValue: checked ? allSelectValue : "",
      }))
    );
  };

  const handleAllSelectChange = (value) => {
    setAllSelectValue(value);
    if (allChecked) {
      setCategories((prevCategories) =>
        prevCategories.map((category) => ({
          ...category,
          selectValue: value,
          tax_profile_id: value,
          items: category.items.map((item) => ({
            ...item,
            selectValue: value,
            tax_profile_id: value,
          })),
        }))
      );

      setStandaloneItems((prevItems) =>
        prevItems.map((item) => ({
          ...item,
          selectValue: value,
          tax_profile_id: value,
        }))
      );
    }
  };

  const handleCategorySelectChange = (categoryIndex, value) => {
    setCategories((prevCategories) => {
      const updatedCategories = [...prevCategories];
      updatedCategories[categoryIndex].selectValue = value;
      updatedCategories[categoryIndex].tax_profile_id = value;
      return updatedCategories;
    });
  };

  const handleItemSelectChange = (categoryIndex, itemIndex, value) => {
    setCategories((prevCategories) => {
      const updatedCategories = [...prevCategories];
      updatedCategories[categoryIndex].items[itemIndex].selectValue = value;
      updatedCategories[categoryIndex].items[itemIndex].tax_profile_id = value;
      return updatedCategories;
    });
  };

  const handleCategoryCheckboxChange = (categoryIndex, checked) => {
    setCategories((prevCategories) => {
      const updatedCategories = [...prevCategories];
      updatedCategories[categoryIndex].checked = checked;
      if (!checked) {
        updatedCategories[categoryIndex].selectValue = "";
      }
      return updatedCategories;
    });
  };

  const handleItemCheckboxChange = (categoryIndex, itemIndex, checked) => {
    setCategories((prevCategories) => {
      const updatedCategories = [...prevCategories];
      updatedCategories[categoryIndex].items[itemIndex].checked = checked;
      if (!checked) {
        updatedCategories[categoryIndex].items[itemIndex].selectValue = "";
      }
      return updatedCategories;
    });
  };

  const handleStandaloneItemSelectChange = (itemIndex, value) => {
    setStandaloneItems((prevItems) => {
      const updatedCategories = [...prevItems];
      updatedCategories[itemIndex].selectValue = value;
      updatedCategories[itemIndex].tax_profile_id = value;
      return updatedCategories;
    });
  };

  const handleStandaloneItemCheckboxChange = (itemIndex, checked) => {
    setStandaloneItems((prevItems) => {
      const updatedCategories = [...prevItems];
      updatedCategories[itemIndex].checked = checked;
      if (!checked) {
        updatedCategories[itemIndex].selectValue = "";
      }
      return updatedCategories;
    });
  };

  const handleSave = () => {
    const payload = {
      categories: categories
        .filter((category) => category.checked)
        .map((category) => ({
          id: category.categoryId,
          name: category.categoryName,
          tax_profile_id: category.tax_profile_id,
        })),
      items: [
        ...categories.flatMap((category) =>
          category.items
            .filter((item) => item.checked)
            .map((item) => ({
              id: item.itemId,
              name: item.itemName,
              tax_profile_id: item.tax_profile_id,
            }))
        ),
        ...standaloneItems
          .filter((item) => item.checked)
          .map((item) => ({
            id: item.itemId,
            name: item.itemName,
            tax_profile_id: item.tax_profile_id,
          })),
      ],
    };
    onSave(payload);
  };
  const handleCountOfCatItems = () => {
    const categoriesCount = categories.length;
    let itemCount = 0;
    categories.map((obj) => {
      itemCount += obj.items.length;
    });
    itemCount += standaloneItems.length;

    return `${categoriesCount} ${Context.langData.categories}, ${itemCount} ${Context.langData.items}`;
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth='md'
      PaperProps={{
        style: { maxHeight: "80vh", width: "60vw", minHeight: "80vh" },
      }}>
      <DialogContent style={{ padding: 0 }}>
        <Grid className={classes.stickyHeader}>
          <Grid
            style={{
              justifyContent: "space-between",
              alignItems: "center",
              display: "flex",
            }}>
            <AvenirBlackH2 label='Reassign tax profile' />
            <IconButton onClick={onClose}>
              <CircleClose />
            </IconButton>
          </Grid>
          <H4 label='Select the replacement tax for each category or item.' />
          <TableContainer>
            <Table className={classes.table} aria-label='data table'>
              <TableHead>
                <TableRow className={classes.headerRow}>
                  {["Category / item", "Current profile", "Replacement"].map(
                    (str, index) => (
                      <TableCell className={classes.cell} key={index}>
                        <AvenirBlackH3 label={str} />
                      </TableCell>
                    )
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow className={classes.dataRow}>
                  <TableCell>
                    <Grid style={{ display: "flex", alignItems: "center" }}>
                      <CustomCheckbox
                        checked={allChecked}
                        onChange={(e) =>
                          handleAllCheckboxChange(e.target.checked)
                        }
                      />
                      <Grid>
                        <AvenirBlackH3 label='All' />
                        <DotText label={handleCountOfCatItems()} />
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell className={classes.cell}>
                    <Grid
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "0px 10px 0px 10px",
                      }}>
                      <DotText label={currentProfileName} />
                      <RightPointingArrow
                        color={allChecked ? "#007191" : "#C3C3C3"}
                      />
                    </Grid>
                  </TableCell>
                  <TableCell className={classes.cell}>
                    <SingleSelect
                      list={taxProfiles.map((profile) => ({
                        value: profile.id,
                        label: profile.name,
                      }))}
                      placeholder='Select one'
                      valueKey='value'
                      displayKey='label'
                      value={allSelectValue}
                      handleChange={(e) =>
                        handleAllSelectChange(e.target.value)
                      }
                      disabled={!allChecked}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Divider
          style={{ backgroundColor: "#EEE", height: "2px", zIndex: 3 }}
        />
        <TableContainer style={{ padding: "0px 15px 0px 15px" }}>
          <Table className={classes.table} aria-label='data table'>
            <TableBody className={classes.scrollableTableBody}>
              {categories.map((category, index) => (
                <React.Fragment key={category.categoryId}>
                  <TableRow className={classes.dataRow}>
                    <TableCell>
                      <Grid style={{ display: "flex", alignItems: "center" }}>
                        <IconButton
                          onClick={() =>
                            setExpandedRows((prev) => ({
                              ...prev,
                              [index]: !prev[index],
                            }))
                          }>
                          {!expandedRows[index] ? <UpVector /> : <DownVector />}
                        </IconButton>
                        <CustomCheckbox
                          checked={category.checked}
                          onChange={(e) =>
                            handleCategoryCheckboxChange(
                              index,
                              e.target.checked
                            )
                          }
                        />
                        <DotText label={category.categoryName} />
                      </Grid>
                    </TableCell>
                    <TableCell className={classes.cell}>
                      <Grid
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          padding: "0px 10px 0px 10px",
                        }}>
                        <DotText label={currentProfileName} />
                        <RightPointingArrow
                          color={category.checked ? "#007191" : "#C3C3C3"}
                        />
                      </Grid>
                    </TableCell>
                    <TableCell className={classes.cell}>
                      <SingleSelect
                        list={taxProfiles.map((profile) => ({
                          value: profile.id,
                          label: profile.name,
                        }))}
                        placeholder='Select One'
                        valueKey='value'
                        displayKey='label'
                        value={category.selectValue}
                        handleChange={(e) =>
                          handleCategorySelectChange(index, e.target.value)
                        }
                        disabled={!category.checked}
                      />
                    </TableCell>
                  </TableRow>

                  {!expandedRows[index] &&
                    category.items.map((item, itemIndex) => (
                      <TableRow key={item.itemId} className={classes.dataRow}>
                        <TableCell>
                          <Grid
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginLeft: "4.5rem",
                            }}>
                            <CustomCheckbox
                              checked={item.checked}
                              onChange={(e) =>
                                handleItemCheckboxChange(
                                  index,
                                  itemIndex,
                                  e.target.checked
                                )
                              }
                            />
                            <DotText label={item.itemName} />
                          </Grid>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          <Grid
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              padding: "0px 10px 0px 10px",
                            }}>
                            <DotText label={currentProfileName} />
                            <RightPointingArrow
                              color={item.checked ? "#007191" : "#C3C3C3"}
                            />
                          </Grid>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          <SingleSelect
                            list={taxProfiles.map((profile) => ({
                              value: profile.id,
                              label: profile.name,
                            }))}
                            placeholder='Select One'
                            valueKey='value'
                            displayKey='label'
                            value={item.selectValue}
                            handleChange={(e) =>
                              handleItemSelectChange(
                                index,
                                itemIndex,
                                e.target.value
                              )
                            }
                            disabled={!item.checked}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                </React.Fragment>
              ))}
              {standaloneItems.map((item, standaloneItemIndex) => (
                <TableRow key={item.itemId} className={classes.dataRow}>
                  <TableCell>
                    <Grid
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "2.5rem",
                      }}>
                      <CustomCheckbox
                        checked={item.checked}
                        onChange={(e) =>
                          handleStandaloneItemCheckboxChange(
                            standaloneItemIndex,
                            e.target.checked
                          )
                        }
                      />
                      <DotText label={item.itemName} />
                    </Grid>
                  </TableCell>
                  <TableCell className={classes.cell}>
                    <Grid
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "0px 10px 0px 10px",
                      }}>
                      <DotText label={currentProfileName} />
                      <RightPointingArrow
                        color={item.checked ? "#007191" : "#C3C3C3"}
                      />
                    </Grid>
                  </TableCell>
                  <TableCell className={classes.cell}>
                    <SingleSelect
                      list={taxProfiles.map((profile) => ({
                        value: profile.id,
                        label: profile.name,
                      }))}
                      placeholder='Select One'
                      valueKey='value'
                      displayKey='label'
                      value={item.selectValue}
                      handleChange={(e) =>
                        handleStandaloneItemSelectChange(
                          standaloneItemIndex,
                          e.target.value
                        )
                      }
                      disabled={!item.checked}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions
        style={{
          justifyContent: "center",
          paddingBottom: "20px",
          paddingTop: "10px",
        }}>
        <Button
          className={style.buttonClass}
          style={{
            backgroundColor: "#FFF",
            width: "45%",
            height: 40,
            border: "2px solid #051D33",
          }}
          variant='contained'
          id='cancel'
          onClick={() => onClose()}>
          <AvenirBlackH4 label={Context.langData.cancel} />
        </Button>
        <Button
          className={style.buttonClass}
          data-qaid='createTaxProfile_saveButtonView'
          style={{
            backgroundColor: "#FFB600",
            width: "45%",
            height: 40,
          }}
          variant='contained'
          id='cancel'
          type='submit'
          onClick={() => handleSave()}>
          <AvenirBlackH4 label={Context.langData.reassign} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReassignTaxProfileModal;
