import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid, Typography } from "@material-ui/core";
import _ from "lodash";
import SimpleTable from "../../../../components/SimpleTable";
import styles from "../../../../styles/index";
import {
  fetchAllTaxProfiles,
  fetchTaxProfileAssociationList,
} from "../../../../redux/actions";
import { AvenirBlackH4, TBold, T, DotText } from "../../../../utils/text";
import CirclePluse from "../../../../components/SVG/PluseIcon";
import InfoOutlined from "../../../../components/SVG/InfoOutlined";
import { CircleCheck, CircleClose } from "../../../../components/SVG";
import MyContext from "../../../../Provider/MyContext";
import SnackBar from "../../../../components/SnackBar";
import { AlertDialog } from "../../../../components";
import { useDrawers } from "../../../../custom-hooks";
import { replaceWithDynamicText } from "../../../../utils/util";
import CreateTaxProfile from "./createTaxProfile";
import EditTaxProfile from "./editTaxProfile";

const TaxProfilesList = () => {
  const style = styles();
  const Context = useContext(MyContext);
  const taxProfiles = useSelector(
    (state) => state.taxConfigurationReducer.taxProfiles
  );
  const taxProfileAssociations = useSelector(
    (state) => state.taxConfigurationReducer.taxProfileAssociations
  );

  const [openCreate, toggleCreateDrawer] = useState(false);
  const [openEdit, toggleEditDrawer] = useState(false);
  const [row, setRow] = useState({});
  const dispatch = useDispatch();
  const [taxes, setTaxes] = useState([]);
  const [snackbar, setSnackbar] = useState(null);
  const [showMore, setShowMore] = useState(false);
  const restId = useSelector((state) => state.loginReducer.selectedRestaurant);
  const [batchReassignResponse, setBatchReassignResponse] = useState([]);
  const { drawers, toggleDrawer } = useDrawers({
    reAssignDialog: false,
  });

  useEffect(() => {
    dispatch(fetchAllTaxProfiles({ id: restId.rest_id }));
    dispatch(fetchTaxProfileAssociationList());
  }, []);

  useEffect(() => {
    setTaxes(processTaxProfiles(taxProfiles, taxProfileAssociations));
  }, [taxProfiles, taxProfileAssociations]);

  function processTaxProfiles(taxProfiles, taxProfileAssociations) {
    return taxProfiles.map((d) => {
      let hasAssigned = false;
      let taxReductionText = Context.langData.tax_profile_not_applied;
      if (d.hasSpecialRule)
        taxReductionText = Context.langData.tax_profile_applied;
      let categoryText = `0 ${Context.langData.categories}, 0 ${Context.langData.items}`;
      if (taxProfileAssociations) {
        const taxProfileAssociation = taxProfileAssociations.find(
          (e) => e.id === d.id
        );
        if (taxProfileAssociation) {
          let itemCount = taxProfileAssociation.items.length;
          taxProfileAssociation.categories.forEach((element) => {
            itemCount += element.items.length;
          });
          categoryText = `${taxProfileAssociation.categories.length} ${
            Context.langData.categories
          }, ${itemCount} ${Context.langData.items}`;
          hasAssigned = !(
            taxProfileAssociation.categories.length === 0 && itemCount === 0
          );
        }
      }
      return {
        ...d,
        taxReductionText,
        categoryText,
        hasAssigned,
      };
    });
  }

  const [columns, setColumns] = useState([
    {
      field: "name",
      display: Context.langData.tax_profile,
      size: "20%",
      sort: false,
    },
    {
      field: "desc",
      display: Context.langData.comment,
      size: "20%",
      sort: false,
    },
    {
      field: "taxes",
      display: "Tax",
      size: "20%",
      sort: false,
      // showSort: false
    },
    {
      field: "taxReductionText",
      display: Context.langData.tax_reduction_column,
      size: "20%",
      sort: false,
    },
    {
      field: "categoryText",
      display: Context.langData.categories,
      size: "20%",
      sort: false,
    },
  ]);
  const onClickAction = (rowValue) => {
    setRow(rowValue);
    toggleEditDrawer(true);
  };
  const btnProps = {};
  btnProps.disabled = true;
  function commonCallback() {
    toggleCreateDrawer(false);
    toggleEditDrawer(false);
    dispatch(fetchAllTaxProfiles({ id: restId.rest_id }));
  }
  const createCallback = (isSuccess) => {
    if (isSuccess) {
      setSnackbar({
        message: Context.langData.tax_profile_create_succ,
        severity: "success",
      });
    } else {
      setSnackbar({
        message: Context.langData.tax_profile_create_failed,
        severity: "error",
      });
    }
    commonCallback();
  };
  const editCallback = (isSuccess) => {
    if (isSuccess) {
      setSnackbar({
        message: Context.langData.tax_profile_edit_succ,
        severity: "success",
      });
    } else {
      setSnackbar({
        message: Context.langData.tax_profile_edit_failed,
        severity: "error",
      });
    }
    commonCallback();
  };
  const deleteCallback = (isSuccess) => {
    if (isSuccess) {
      setSnackbar({
        message: Context.langData.tax_profile_delete_succ,
        severity: "success",
      });
    } else {
      setSnackbar({
        message: Context.langData.tax_profile_delete_failed,
        severity: "error",
      });
    }
    commonCallback();
  };

  const sortingOnClick = (value, sortValue, index) => {
    const columnData = [...columns];
    sortValue = !sortValue;
    columnData[index] = { ...columnData[index], sort: !sortValue };
    setColumns(columnData);
    const sortComments = _.orderBy(
      taxes,
      [(d) => d[value].toLowerCase()],
      [sortValue ? "desc" : "asc"]
    );
    setTaxes(processTaxProfiles(sortComments, taxProfileAssociations));
    const sortTaxes = _.orderBy(
      taxes,
      [(d) => d[value].toLowerCase()],
      [sortValue ? "desc" : "asc"]
    );
    setTaxes(processTaxProfiles(sortTaxes, taxProfileAssociations));
  };

  const handleBatchReassignmentCallback = (response) => {
    if (response) {
      dispatch(fetchTaxProfileAssociationList());
      dispatch(fetchAllTaxProfiles({ id: restId.rest_id }));

      const messageArray = Object.keys(response).flatMap((item) => {
        if (response[item].length > 0 && response[item][0].length > 0) {
          if (item === "succeed_categories") {
            return {
              status: "success",
              name: "categories",
              count: response[item][0].length,
            };
          }
          if (item === "succeed_items") {
            return {
              status: "success",
              name: "items",
              count: response[item][0].length,
            };
          }
          if (item === "failed_categories") {
            return {
              status: "failed",
              name: "categories",
              count: response[item][0].length,
            };
          }
          if (item === "failed_items") {
            return {
              status: "failed",
              name: "items",
              count: response[item][0].length,
            };
          }
        }
        return [];
      });

      setBatchReassignResponse(messageArray);
      toggleDrawer("reAssignDialog", true);
    }
  };

  const renderSummary = () => (
    <Grid
      style={{
        display: "flex",
        flexDirection: "column",
      }}>
      {batchReassignResponse?.map(
        (item, index) =>
          item && (
            <Grid
              key={index}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}>
              <Grid style={{ marginRight: "3px" }}>
                {item.status === "success" ? (
                  <CircleCheck width={16} height={16} color='#007191' />
                ) : (
                  <CircleClose width={16} height={16} color='#AB1212' />
                )}
              </Grid>
              {item.count === 1 && item.name === "categories" && (
                <AvenirBlackH4
                  label={replaceWithDynamicText(
                    item.status === "success"
                      ? Context.langData.moved_category
                      : Context.langData.failed_moved_category,
                    item
                  )}
                />
              )}
              {item.count > 1 && item.name === "categories" && (
                <AvenirBlackH4
                  label={replaceWithDynamicText(
                    item.status === "success"
                      ? Context.langData.moved_categories
                      : Context.langData.failed_moved_categories,
                    item
                  )}
                />
              )}
              {item.count === 1 && item.name === "items" && (
                <AvenirBlackH4
                  label={replaceWithDynamicText(
                    item.status === "success"
                      ? Context.langData.moved_item
                      : Context.langData.failed_moved_item,
                    item
                  )}
                />
              )}
              {item.count > 1 && item.name === "items" && (
                <AvenirBlackH4
                  label={replaceWithDynamicText(
                    item.status === "success"
                      ? Context.langData.moved_items
                      : Context.langData.failed_moved_items,
                    item
                  )}
                />
              )}
            </Grid>
          )
      )}
    </Grid>
  );

  return (
    <div data-qaid='taxeslist_div'>
      <Grid
        data-qaid='taxesList-blurb'
        style={{
          display: "flex",
          flexDirection: "row",
          padding: 15,
          backgroundColor: "#F3FAFD",
          width: "95%",
          height: "auto",
          borderRadius: 5,
          marginBottom: 26,
          marginLeft: 20,
        }}>
        <Grid data-qaid='taxesList-blurb-InfoIcon'>
          <InfoOutlined width={24} height={24} color='#004C60' />
        </Grid>
        <Grid style={{ paddingLeft: 10, width: "100%" }}>
          {showMore ? (
            <Grid
              data-qaid='taxesList-blurb-message'
              style={{
                color: "#051D33",
                fontSize: 14,
                fontFamily: "AvenirLT",
                lineHeight: 1.5,
                paddingTop: 4,
              }}>
              {Context.langData.tax_important_reminder}
              <Typography
                onClick={() => setShowMore(!showMore)}
                style={{ cursor: "pointer", color: "#004C60" }}>
                {showMore ? " Show Less" : ""}
              </Typography>
            </Grid>
          ) : (
            <Grid
              style={{
                flexDirection: "row",
                display: "flex",
                paddingTop: 4,
              }}>
              <Grid
                data-qaid='taxesList-blurb-message'
                style={{ width: "83%" }}>
                <DotText label={Context.langData.tax_important_reminder} />
              </Grid>
              <Typography
                onClick={() => setShowMore(!showMore)}
                style={{
                  cursor: "pointer",
                  color: "#004C60",
                  marginLeft: 3,
                }}>
                {showMore ? "" : "Read More"}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid
        data-qaid='taxeslist_grid'
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "center",
          marginRight: 20,
          marginBottom: 10,
          marginTop: 32,
        }}>
        <Button
          className={style.buttonClass}
          data-qaid='taxeslist_creategrid'
          style={{
            backgroundColor: "#FFB600",
            color: "white",
            marginRight: 10,
          }}
          variant='contained'
          id='cancel'
          onClick={() => toggleCreateDrawer(true)}>
          <CirclePluse data-qaid='taxeslist_createpluse' />
          <AvenirBlackH4
            data-qaid='taxeslist_createlabel'
            label={Context.langData.create_taxes_profile}
            style={{ paddingLeft: 5 }}
          />
        </Button>
      </Grid>
      {taxes.length > 0 ? (
        <SimpleTable
          data-qaid='taxProfiles_simpleTable'
          columns={columns}
          rows={taxes}
          isEditArrow
          isOnClick
          sortingOnClick={sortingOnClick}
          onRowClick={onClickAction}
        />
      ) : (
        <Grid
          data-qaid='customers_labelView'
          item
          lg={12}
          style={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            marginTop: "10%",
            flexDirection: "column",
          }}>
          <Grid
            item
            style={{
              maxWidth: 410,
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
            }}>
            <TBold
              label={Context.langData.no_profile_created_yet}
              style={{ textAlign: "center", fontSize: 32 }}
            />
            <T
              label={Context.langData.please_create_tax_profile}
              style={{ marginTop: 20, fontSize: 18, color: "#051D33" }}
            />
          </Grid>
        </Grid>
      )}
      {openCreate && (
        <CreateTaxProfile
          data-qaid='taxeslist_createtaxes'
          open={openCreate}
          taxes={taxes}
          onClose={() => toggleCreateDrawer(false)}
          callback={createCallback}
        />
      )}
      {openEdit && (
        <EditTaxProfile
          data-qaid='taxeslist_edittaxes'
          open={openEdit}
          row={row}
          taxes={taxes}
          onClose={() => toggleEditDrawer(false)}
          editcallback={editCallback}
          deleteCallback={deleteCallback}
          reassignmentCallBack={handleBatchReassignmentCallback}
        />
      )}
      {snackbar && (
        <SnackBar
          data-qaid='taxeslist_snackbar'
          open
          setOpen={() => setSnackbar(null)}
          severity={snackbar.severity}
          message={snackbar.message}
        />
      )}
      <AlertDialog
        open={drawers.reAssignDialog}
        onClose={() => {
          toggleDrawer("reAssignDialog", false);
        }}
        title={Context.langData.reassignment_complete}
        primaryButtonText={Context.langData.done}
        onPrimaryAction={() => toggleDrawer("reAssignDialog", false)}
        // eslint-disable-next-line react/no-children-prop
        children={renderSummary()}
        primaryButtonType='single'
      />
    </div>
  );
};

export default TaxProfilesList;
