import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Button,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@material-ui/core";
import { Check as CheckIcon } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { AvenirBlackH3 } from "../utils/text";
import { UpVector, DownVector } from "./SVG";

const colors = [
  { bg_colour: "#FFFFFF", font_colour: "#000" },
  { bg_colour: "#EE9DA8", font_colour: "#000" },
  { bg_colour: "#FFB899", font_colour: "#000" },
  { bg_colour: "#FFDD8A", font_colour: "#000" },
  { bg_colour: "#CDE692", font_colour: "#000" },
  { bg_colour: "#B1E5CE", font_colour: "#000" },
  { bg_colour: "#B2E8EB", font_colour: "#000" },
  { bg_colour: "#B1D3ED", font_colour: "#000" },
  { bg_colour: "#D0C0E3", font_colour: "#000" },
  { bg_colour: "#707272", font_colour: "#fff" },
  { bg_colour: "#DA2B42", font_colour: "#fff" },
  { bg_colour: "#FD6423", font_colour: "#000" },
  { bg_colour: "#FFB600", font_colour: "#000" },
  { bg_colour: "#93C90F", font_colour: "#000" },
  { bg_colour: "#00AC61", font_colour: "#000" },
  { bg_colour: "#30BBC9", font_colour: "#000" },
  { bg_colour: "#0671C5", font_colour: "#fff" },
  { bg_colour: "#6834A4", font_colour: "#fff" },
  { bg_colour: "#010E1B", font_colour: "#fff" },
  { bg_colour: "#5C111C", font_colour: "#fff" },
  { bg_colour: "#8B3713", font_colour: "#fff" },
  { bg_colour: "#986C00", font_colour: "#fff" },
  { bg_colour: "#516E09", font_colour: "#fff" },
  { bg_colour: "#055E35", font_colour: "#fff" },
  { bg_colour: "#036468", font_colour: "#fff" },
  { bg_colour: "#03508C", font_colour: "#fff" },
  { bg_colour: "#391D5A", font_colour: "#fff" },
];

const useStyles = makeStyles({
  colorBox: {
    width: 44,
    height: 44,
    borderRadius: 5,
    position: "relative",
    cursor: "pointer",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    "&:hover": {
      "&::before": {
        content: '""',
        position: "absolute",
        top: -4,
        left: -4,
        right: -4,
        bottom: -4,
        borderRadius: 5,
        border: "3px solid #92D2E7",
        backgroundColor: "transparent",
      },
    },
  },
  selectedBorder: {
    border: "2px solid #707272",
  },
  checkIcon: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  previewTile: {
    padding: "8px",
    borderRadius: "5px",
    boxShadow: "0px 2px 4px 2px rgba(196, 196, 196, 0.25)",
    textAlign: "center",
    height: "83px",
    width: "143px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  radio: {
    "&$checked": {
      color: "#004C60",
    },
    fontSize: 14,
    "&.MuiFormControlLabel-label.MuiTypography-body1": {
      fontSize: 14,
    },
  },
  label: {
    fontSize: 14,
  },
});

const splitIntoRows = (arr, rowSize) => {
  const rows = [];
  for (let i = 0; i < arr.length; i += rowSize) {
    rows.push(arr.slice(i, i + rowSize));
  }
  return rows;
};

const ColorSelector = ({
  onSelector = () => {},
  name = "Category",
  disableRadio = true,
  initialColors,
  style,
}) => {
  const [selectedColor, setSelectedColor] = useState(colors[0]);
  const [backgroundType, setBackgroundType] = useState("fullBackground");
  const [dropDown, setDropDown] = useState(false);
  const classes = useStyles();

  const handleColorSelect = (color) => {
    setSelectedColor(color);
    onSelector(color);
  };

  const colorRows = splitIntoRows(colors, 9);
  useEffect(() => {
    if (initialColors && initialColors.bg_colour && initialColors.font_colour)
      setSelectedColor(initialColors);
  }, [initialColors]);
  return (
    <Box
      style={{
        borderTop: "1px solid #EEE",
        borderBottom: "1px solid #EEE",
        paddingTop: 20,
        paddingBottom: 20,
        ...style,
      }}>
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}>
        <Box
          className={classes.previewTile}
          style={{
            background:
              backgroundType === "fullBackground"
                ? selectedColor.bg_colour
                : `linear-gradient(to right, ${selectedColor.bg_colour} 5%, #FFFFFF 5%)`,
          }}>
          <AvenirBlackH3
            style={{
              color:
                backgroundType === "fullBackground"
                  ? selectedColor.font_colour
                  : "#000",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 2,
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            label={name}
          />
        </Box>
        <Button
          onClick={() => setDropDown((prev) => !prev)}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "35%",
          }}>
          <AvenirBlackH3
            style={{
              color: "#004C60",
              lineHeight: "20px",
              fontSize: "14px",
              fontWeight: 550,
              fontFamily: "AvenirLT",
              marginLeft: 8,
            }}
            label='Change button style'
          />
          {dropDown ? (
            <UpVector color='#007191' />
          ) : (
            <DownVector color='#007191' />
          )}
        </Button>
      </Box>

      {dropDown && (
        <Grid>
          {!disableRadio && (
            <RadioGroup
              row
              aria-label='colorType'
              name='colorType'
              value={backgroundType}
              onChange={(e) => setBackgroundType(e.target.value)}
              style={{
                width: "100%",
                flexDirection: "row",
                display: "flex",
                justifyContent: "flex-start",
                paddingTop: 10,
              }}
              classes={{
                root: classes.radio,
                label: classes.label,
              }}>
              <FormControlLabel
                value='fullBackground'
                control={
                  <Radio
                    classes={{
                      root: classes.radio,
                      label: classes.label,
                    }}
                  />
                }
                label='Full background'
              />
              <FormControlLabel
                style={{ marginLeft: "25px" }}
                value='accent'
                control={
                  <Radio
                    classes={{
                      root: classes.radio,
                      label: classes.label,
                    }}
                  />
                }
                label='Accent'
              />
            </RadioGroup>
          )}
          {colorRows.map((row, rowIndex) => (
            <Grid
              container
              key={rowIndex}
              spacing={1}
              style={{ marginTop: 20 }}
              justifyContent='space-between'>
              {row.map((color, index) => (
                <Grid item key={color.bg_colour}>
                  <Box
                    className={`${classes.colorBox}`}
                    style={{
                      backgroundColor: color.bg_colour,
                      boxShadow:
                        index === 0 && rowIndex === 0
                          ? "inset 0px 0px 0px 1px #707272"
                          : "",
                    }}
                    onClick={() => handleColorSelect(color)}>
                    {selectedColor.bg_colour === color.bg_colour && (
                      <CheckIcon
                        className={classes.checkIcon}
                        style={{ color: color.font_colour }}
                      />
                    )}
                  </Box>
                </Grid>
              ))}
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default ColorSelector;
