/* eslint-disable no-undef */
import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  Container,
  TextField,
  makeStyles,
  Grid,
  FormHelperText,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";
import { useLDClient } from "launchdarkly-react-client-sdk";
import MyContext from "../../Provider/MyContext";
import Page from "../../components/Page";
import { login, fetchRolePermissions, forgotEmail } from "../../redux/actions";
import { H4, AvenirBlackH4, H2, H5 } from "../../utils/text";
import InfoOutlined from "../../components/SVG/InfoOutlined";
import CenterModal from "../../components/CenterModal";
import InputText from "../../components/InputText";
import LoginLogo from "../../components/SVG/LoginLogo";
import SnackBar from "../../components/SnackBar";
import styles from "../../styles/index";
import CircleCheck from "../../components/SVG/CircleCheck";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F3FAFD",
    height: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  link: {
    color: `rgb(0, 76, 96)`,
    textDecoration: "none",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
    "&:focus": {
      outline: "none",
      textDecoration: "underline",
    },
  },
}));

const LoginView = () => {
  const classes = useStyles();
  const style = styles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loginDetails = useSelector((state) => state.loginReducer.loginDetails);
  const [alertData, setAlertData] = useState({
    open: false,
    severity: "success",
    msg: "",
  });
  const Context = useContext(MyContext);
  const [showForgotSuccess, setShowForgotSuccess] = useState(false);
  const [openForgotPassword, setOpenForgotPassword] = useState(false);
  const ldClient = useLDClient();
  useEffect(() => {
    if (loginDetails) {
      if (loginDetails.restaurants && loginDetails.restaurants.length > 0) {
        dispatch({ type: "CLEAR_BUILDER" });
        if (!loginDetails?.mfa_session_token) {
          dispatch({ type: "MFA_REQUIRED", payload: true });
          localStorage.setItem("mfa_required", 1);
          navigate("/mfa", { state: { fromLogin: true } });
        } else {
          dispatch(fetchRolePermissions());
          if (loginDetails.restaurants.length > 1) {
            navigate("/branches");
          } else if (loginDetails.restaurants.length === 1) {
            if (loginDetails.restaurants[0].tz) {
              moment.tz.setDefault(loginDetails.restaurants[0].tz);
            }
            const context = {
              kind: "restaurant",
              key: "restaurant",
              restId: loginDetails.restaurants[0].rest_id,
            };
            ldClient.identify(context, null, () => {});
            navigate("/home/dashboard");
          }
        }
      }
    }
  }, [loginDetails]);
  const loginFailedCallback = (data) => {
    if (data.status_code === 403) {
      navigate("/lockout");
    } else {
      const d = { open: true, severity: "error", msg: data.status };
      setAlertData(d);
    }
  };
  const forgotCallback = () => {
    setOpenForgotPassword(false);
    setShowForgotSuccess(true);
  };
  const forgotSuccCallback = () => {
    setShowForgotSuccess(false);
    navigate("/");
  };
  return (
    <Page
      data-qaid='loginView_page'
      className={classes.root}
      title={Context.langData.Login}>
      <Box
        data-qaid='loginView_box'
        display='flex'
        flexDirection='column'
        height='100%'
        justifyContent='center'>
        <Container data-qaid='loginView_container' maxWidth='sm'>
          <Formik
            data-qaid='loginView_formik'
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email(Context.langData.email_valid_error)
                .max(255)
                .required(Context.langData.email_required_error),
              password: Yup.string()
                .max(255)
                .required(Context.langData.password_required_error),
            })}
            onSubmit={(values) => {
              dispatch(
                login(
                  {
                    email: values.email,
                    password: values.password,
                    session_token:
                      localStorage.getItem("session_token") || null,
                  },
                  (status, response) => {
                    if (!status) {
                      loginFailedCallback(response);
                    }
                  }
                )
              );
            }}>
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid
                  data-qaid='loginView_formik_main_grid'
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: 49,
                  }}>
                  <LoginLogo />
                </Grid>
                <Grid
                  data-qaid='loginView_formik_email_grid'
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                  }}>
                  <InputText
                    data-qaid='loginView_formik_email_input'
                    error={touched.email && errors.email}
                    fullWidth
                    helperText={touched.email && errors.email}
                    margin='normal'
                    name='email'
                    id='email'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type='email'
                    value={values.email}
                    variant='outlined'
                    placeholder={Context.langData.login_email}
                    paddingBottom={5}
                  />
                </Grid>
                <Grid
                  data-qaid='loginView_formik_password_grid'
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                    marginTop: 22,
                  }}>
                  <InputText
                    data-qaid='loginView_formik_password_input'
                    error={touched.password && errors.password}
                    id='password'
                    fullWidth
                    helperText={touched.password && errors.password}
                    margin='normal'
                    name='password'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type='password'
                    variant='outlined'
                    placeholder={Context.langData.password}
                    paddingBottom={5}
                  />
                </Grid>
                <Box
                  data-qaid='loginView_formik_action_box'
                  my={2}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 32,
                    marginBottom: 20,
                  }}>
                  <Button
                    data-qaid='loginView_formik_login_button'
                    style={{ backgroundColor: "#FFB600" }}
                    type='submit'
                    id='login_btn'
                    variant='contained'
                    className={style.buttonClass}>
                    <AvenirBlackH4
                      data-qaid='loginView_formik_login_label'
                      style={{ color: "#051D33" }}
                      label={Context.langData.Signin}
                    />
                  </Button>
                </Box>
                <AvenirBlackH4
                  data-qaid='loginView_formik_forgot_label'
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#004C60",
                    cursor: "pointer",
                  }}
                  label={Context.langData.Forgot_password}
                  className={classes.link}
                  onClick={() => {
                    setOpenForgotPassword(true);
                  }}
                />
              </form>
            )}
          </Formik>
        </Container>
      </Box>
      {openForgotPassword && (
        <ForgotPassword
          open={openForgotPassword}
          onClose={() => setOpenForgotPassword(false)}
          title={Context.langData.update_password}
          callback={forgotCallback}
        />
      )}
      {showForgotSuccess && (
        <ForgotPasswordSuccess
          open={showForgotSuccess}
          onClose={() => setShowForgotSuccess(false)}
          callback={forgotSuccCallback}
          duration={6000}
        />
      )}
      <SnackBar
        data-qaid='loginView_formik_message'
        open={alertData.open}
        severity={alertData.severity}
        message={alertData.msg}
        handleClose={() => console.log()}
      />
    </Page>
  );
};

const ForgotPassword = ({ open, onClose, callback, title, info = "" }) => {
  const Context = useContext(MyContext);
  const dispatch = useDispatch();
  return (
    <CenterModal
      open={open}
      onClose={onClose}
      borderRadius={5}
      style={{ width: 475 }}>
      <Formik
        data-qaid='loginView_formik'
        initialValues={{
          email: "",
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email(Context.langData.email_valid_error)
            .max(255)
            .required(Context.langData.email_required_error),
        })}
        onSubmit={(values) => {
          dispatch(forgotEmail({ email: values.email }, callback));
        }}>
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid style={{ width: 475 }}>
              <Grid
                style={{ width: "100%", paddingLeft: 32, paddingRight: 32 }}>
                <H2
                  data-qaid='editcomment_header'
                  style={{ marginTop: 32 }}
                  label={title}
                />
                <H4
                  label={Context.langData.forgotpassword_body}
                  style={{ marginBottom: 32, marginTop: 16 }}
                />
                <Grid container style={{ paddingBottom: 32 }}>
                  <Grid
                    container
                    style={{ display: "flex", flexDirection: "column" }}>
                    <AvenirBlackH4
                      data-qaid='editcomment_valuelabel'
                      label={Context.langData.email_header}
                      style={{ marginBottom: 8 }}
                    />
                    <TextField
                      data-qaid='loginView_formik_email_input'
                      style={{
                        backgroundColor: "#FFFFFF",
                        borderRadius: 4,
                        display: "flex",
                        marginTop: 8,
                      }}
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      helperText={touched.email && errors.email}
                      margin='normal'
                      name='email'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type='email'
                      value={values.email}
                      variant='outlined'
                    />
                    {info && (
                      <FormHelperText
                        style={{
                          color: "#051D33",
                          display: "flex",
                          margin: "8px 0px 0px 0px",
                        }}>
                        <InfoOutlined
                          width={16}
                          height={16}
                          color='#051D33'
                          style={{ marginRight: 5, width: "10%" }}
                        />
                        <H5 label={info} />
                      </FormHelperText>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                data-qaid='editcomment_action_grid'
                container
                style={{
                  backgroundColor: "#FAFAFA",
                  borderTop: "1px solid #C3C3C3",
                  borderBottomRightRadius: 5,
                  borderBottomLeftRadius: 5,
                }}>
                <Grid
                  data-qaid='editcomment_cancel_btn'
                  item
                  xs={12}
                  lg={12}
                  sm={12}
                  md={12}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: 32,
                    marginBottom: 32,
                    paddingRight: 32,
                  }}>
                  <Button
                    data-qaid='editcomment_cancel_button'
                    onClick={onClose}
                    variant='outlined'
                    style={{
                      backgroundColor: "#FFFFFF",
                      marginRight: 16,
                      height: 40,
                      width: 103,
                      border: "2px solid #051D33",
                    }}
                    type='cancel'>
                    <AvenirBlackH4
                      data-qaid='editcomment_cancel_text'
                      label={Context.langData.cancel}
                    />
                  </Button>
                  <Button
                    data-qaid='editcomment_save_btn'
                    variant='contained'
                    type='submit'
                    style={{
                      backgroundColor: "#FFB600",
                      height: 40,
                      width: 109,
                    }}>
                    <AvenirBlackH4
                      data-qaid='editcomment_save_text'
                      label={Context.langData.send}
                    />
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </CenterModal>
  );
};
const ForgotPasswordSuccess = ({ open, onClose, callback }) => {
  const Context = useContext(MyContext);
  return (
    <CenterModal
      open={open}
      style={{ width: 521, height: 314 }}
      onClose={onClose}>
      <Grid
        style={{
          width: 521,
          height: 314,
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}>
        <CircleCheck style={{ marginTop: 48 }} />
        <H2
          data-qaid='editcomment_header'
          style={{ marginTop: 32 }}
          label={Context.langData.forgot_password}
        />
        <H4
          style={{ marginTop: 16, color: "#373737" }}
          label={Context.langData.forgot_sucess}
        />
        <Button
          data-qaid='editcomment_save_btn'
          variant='contained'
          style={{
            backgroundColor: "#FFB600",
            height: 40,
            width: 109,
            marginTop: 32,
          }}
          onClick={callback}>
          <AvenirBlackH4
            data-qaid='editcomment_save_text'
            label={Context.langData.go_back}
          />
        </Button>
      </Grid>
    </CenterModal>
  );
};

export default LoginView;
export { ForgotPassword, ForgotPasswordSuccess };
