import React, { useEffect, useState, useContext } from "react";
import { useDispatch } from "react-redux";
import {
  makeStyles,
  Button,
  Grid,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import styles from "../../../../styles/index";
import RightDrawer from "../../../../components/RightDrawer";
import {
  fetchAssignKitchen,
  saveAssignKitchen,
  fetchAllCategories,
} from "../../../../redux/actions";
import MyContext from "../../../../Provider/MyContext";
import { H4, H2, H3, AvenirBlackH4, DotText } from "../../../../utils/text";
import CloseCircle from "../../../../components/SVG/CloseCircle";
import SettingsIcon from "../../../../components/SVG/Settings";
import DownVector from "../../../../components/SVG/DownVector";

const useStyles = makeStyles((theme) => ({
  MuiAccordionroot: {
    "&.MuiAccordion-root:before": {
      backgroundColor: "#EEE",
      marginLeft: 10,
      marginRight: 10,
    },
  },
  radio: {
    "&$checked": {
      color: "#004C60",
    },
  },
  checked: {
    color: "#707272",
  },
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  mainDiv: {
    backgroundColor: theme.palette.background.paper,
    marginTop: "2%",
  },
  label: {
    width: "inherit",
  },
}));

const AssignMenuDrawer = ({
  open,
  callback,
  onClose,
  KitchensList,
  categories,
}) => {
  const Context = useContext(MyContext);
  const style = styles();
  const [checkedList, setCheckedList] = useState([]);
  const [kitchenIdsList, setKitchenIdsList] = useState([]);
  const [count, setCount] = useState(0);
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    dispatch(fetchAllCategories());
  }, []);

  useEffect(() => {
    dispatch(fetchAssignKitchen({}, assignKitchenData));
  }, [categories]);

  useEffect(() => {
    if (categories.length > 0) {
      setCheckedList([categories[0].cat_id]);
    }
  }, [categories]);

  const assignKitchenData = (response) => {
    if (categories && categories.length > 0) {
      const array = [];
      categories.map((item) => {
        if (response && response.length) {
          const filterArray = response.filter(
            (e) => e.menu_cat_id === item.cat_id
          );
          if (filterArray.length > 0) {
            const obj = {};
            const kitchnIdArray = [];
            obj.cat_id = item.cat_id;
            filterArray.map((catItem) => {
              if (catItem.kitchen_id) {
                kitchnIdArray.push(catItem.kitchen_id);
              }
            });
            obj.kitchen_ids = kitchnIdArray;
            array.push(obj);
          }
          // else{
          //   var obj = {cat_id:item.cat_id}
          // if(_DefaultKitchen){
          //   obj["kitchen_ids"] = [_DefaultKitchen.kitchen_id]
          // }
          // array.push(obj)
          // }
        }
        // else{
        //   console.log(KitchensList.length, 'lenghtjtj')
        //   if(KitchensList.length <= 1){
        //     var obj = {cat_id:item.cat_id}
        //     if(_DefaultKitchen){
        //       obj["kitchen_ids"] = [_DefaultKitchen.kitchen_id]
        //     }
        //     array.push(obj)
        //   }
        // }
      });
      setKitchenIdsList(array);
    }
  };
  function checkIt(id) {
    const permission = [...checkedList];
    const index = permission.indexOf(id);
    if (index === -1) {
      permission.push(id);
    } else {
      permission.splice(index, 1);
    }
    setCheckedList(permission);
  }
  const save = () => {
    const array = [];
    if (categories && categories.length > 0) {
      categories.map((item) => {
        const obj = {};
        let kitchenName = null;
        let kitchenId = null;
        if (kitchenIdsList && kitchenIdsList.length > 0) {
          const catItem = kitchenIdsList.filter(
            (e) => e.cat_id === item.cat_id
          );
          if (catItem.length > 0) {
            if (catItem[0].kitchen_ids && catItem[0].kitchen_ids.length > 0) {
              if (catItem[0].kitchen_ids[0] && catItem[0].kitchen_ids !== 0) {
                const kitchenObj = KitchensList.filter(
                  (e) => e.kitchen_id === catItem[0].kitchen_ids[0]
                );
                if (kitchenObj.length > 0) {
                  kitchenName = kitchenObj[0].name;
                  kitchenId = kitchenObj[0].kitchen_id;
                }
              }
            }
          }
        }
        obj.menu_cat_id = item.cat_id;
        obj.menu_cat_name = item.name;
        obj.kitchen_name = kitchenName;
        obj.kitchen_id = kitchenId;
        array.push(obj);
      });
    }
    dispatch(saveAssignKitchen(array, handleCallback));
  };
  const handleCallback = (value) => {
    callback(value, count);
  };
  const isCheckedKitchen = (catId) => {
    let value = "";
    if (kitchenIdsList && kitchenIdsList.length > 0) {
      const catItem = kitchenIdsList.filter((e) => e.cat_id === catId);
      if (
        catItem.length > 0 &&
        catItem[0].kitchen_ids &&
        catItem[0].kitchen_ids.length > 0
      ) {
        // eslint-disable-next-line prefer-destructuring
        value = catItem[0].kitchen_ids[0];
      }
    }
    return value;
  };
  function KitchenCheckIt(kitchenId, catId) {
    const Ids = [...kitchenIdsList];
    const catArray = Ids.filter((e) => e.cat_id === catId);
    if (catArray.length > 0) {
      const elementsIndex = Ids.findIndex(
        (element) => element.cat_id === catId
      );
      const kitchenIds = [];
      kitchenIds.push(parseInt(kitchenId, 10));
      Ids[elementsIndex] = { ...Ids[elementsIndex], kitchen_ids: kitchenIds };
    } else {
      const obj = { cat_id: catId, kitchen_ids: [parseInt(kitchenId, 10)] };
      Ids.push(obj);
    }
    setCount(count + 1);
    setKitchenIdsList(Ids);
  }
  return (
    <RightDrawer
      data-qaid='assignkitchen_centermodal'
      open={open}
      onClose={onClose}
      padding={1}>
      <Grid
        style={{
          display: "flex",
          height: "100%",
          flexDirection: "column",
          width: 600,
        }}>
        <Grid
          style={{
            display: "flex",
            height: "85%",
            flexDirection: "column",
            overflow: "auto",
            zIndex: 999999,
            paddingBottom: 20,
          }}>
          <Grid
            data-qaid='assignkitchen_close_grid'
            style={{
              display: "flex",
              justifyContent: "flex-end",
              cursor: "pointer",
              marginTop: 20,
              marginRight: 10,
            }}>
            <CloseCircle
              data-qaid='assignkitchen_closeicon'
              size={20}
              onClick={onClose}
              style={{ marginRight: 20 }}
            />
          </Grid>
          <Grid
            data-qaid='assignkitchen_settings_grid'
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              marginLeft: 56,
              marginBottom: 10,
            }}>
            <SettingsIcon
              data-qaid='assignkitchen_setting_icon'
              style={{ marginRight: 10 }}
              width={16}
              height={16}
              color='#707272'
            />
            <H4
              data-qaid='assignkitchen_settings'
              label={Context.langData.settings}
              style={{ color: "#707272" }}
            />
          </Grid>
          <H2
            data-qaid='assignlitchen_title'
            style={{ marginLeft: 56, marginBottom: 20, marginRight: 56 }}
            label={Context.langData.assign_kitchen_account}
          />
          <H4
            data-qaid='assignkitchen_title'
            style={{
              marginLeft: 56,
              color: "#000000",
              marginRight: 56,
              marginBottom: 10,
            }}
            label={Context.langData.assign_what_kitchen_the_orders}
          />
          <Grid style={{ overflowX: "auto" }}>
            {categories && categories.length > 0 ? (
              categories.map((item, index) => (
                <Accordion
                  data-qaid='assignkitchen_accordion'
                  key={index}
                  expanded={checkedList.indexOf(item.cat_id) !== -1}
                  onChange={() => checkIt(item.cat_id)}
                  style={{
                    marginLeft: 56,
                    marginRight: 56,
                    paddingTop: 10,
                    boxShadow: "none",
                  }}
                  classes={{
                    root: classes.MuiAccordionroot,
                  }}>
                  <AccordionSummary
                    data-qaid='assignkitchen_accrdionsummary'
                    expandIcon={<DownVector />}
                    aria-controls='panel2a-content'
                    id='panel2a-header'>
                    <H3 data-qaid='assignkitchen_cat_name' label={item.name} />
                  </AccordionSummary>
                  <div
                    style={{
                      border: "1px solid #EEE",
                      borderTopWidth: "1px",
                      marginLeft: 10,
                      marginRight: 10,
                    }}
                  />
                  <AccordionDetails style={{ maxWidth: "100%" }}>
                    <RadioGroup
                      data-qaid='assignkitchen_kitchenRadiogroup'
                      key={index}
                      row
                      aria-label='position'
                      name='position'
                      value={isCheckedKitchen(item.cat_id)}
                      onChange={(val) =>
                        KitchenCheckIt(val.target.value, item.cat_id)
                      }
                      style={{
                        flexDirection: "column",
                        display: "flex",
                        width: "100%",
                      }}
                      classes={{
                        root: classes.radio,
                      }}>
                      {KitchensList && KitchensList.length > 0 ? (
                        KitchensList.map((kitchenItem) => (
                          <FormControlLabel
                            data-qaid='assignkitchen_kitchen_radio'
                            value={kitchenItem.kitchen_id}
                            style={{ minWidth: "100%" }}
                            control={
                              <Radio
                                classes={{
                                  root: classes.radio,
                                }}
                              />
                            }
                            label={
                              <DotText
                                label={kitchenItem.name}
                                style={
                                  kitchenItem.name.length > 25
                                    ? { width: "50%" }
                                    : null
                                }
                              />
                            }
                          />
                        ))
                      ) : (
                        <Grid data-qaid='assignkitchen_empty_kitchens' />
                      )}
                    </RadioGroup>
                  </AccordionDetails>
                </Accordion>
              ))
            ) : (
              <Grid />
            )}
          </Grid>
        </Grid>
        <Grid
          style={{
            display: "flex",
            height: "15%",
            flexDirection: "column",
            justifyContent: "flex-end",
            marginTop: 15,
          }}>
          <Grid
            data-qaid='assignkitchen_action_grid'
            container
            style={{
              backgroundColor: "#FAFAFA",
              border: "1px solid #C3C3C3",
            }}>
            <Grid
              data-qaid='assignkitchen_cancel_btn'
              item
              xs={12}
              lg={12}
              sm={12}
              md={12}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 32,
                marginBottom: 32,
              }}>
              <Button
                className={style.buttonClass}
                data-qaid='assignkitchen_cancel_button'
                onClick={onClose}
                variant='contained'
                style={{
                  backgroundColor: "#FFF",
                  marginRight: 16,
                  height: 40,
                  width: 103,
                  border: "2px solid #051D33",
                }}>
                <AvenirBlackH4
                  data-qaid='assignkitchen_cancel_text'
                  label={Context.langData.cancel}
                />
              </Button>
              <Button
                className={style.buttonClass}
                data-qaid='assignkitchen_save_btn'
                variant='contained'
                style={{
                  backgroundColor: "#FFB600",
                  marginRight: 32,
                  height: 40,
                  width: 90,
                }}
                onClick={save}>
                <AvenirBlackH4
                  data-qaid='assignkitchen_save_text'
                  label={Context.langData.save}
                />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </RightDrawer>
  );
};

export default AssignMenuDrawer;
