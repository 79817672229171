export function SaveToSession(key, value) {
  sessionStorage.setItem(key, value);
}

export function ReadFromSession(key) {
  return sessionStorage.getItem(key);
}

export function RemoveFromSession(key) {
  return sessionStorage.removeItem(key);
}
