/* eslint-disable no-restricted-globals */
import { useState, useEffect, useContext } from "react";
import _ from "lodash";
import MyContext from "../Provider/MyContext";

const defaultOrderType = {
  name: "",
  description: "",
  order_channel_id: 1,
  delivery_mode_id: 2,
};

const useOrderType = (initialData = null) => {
  const { langData } = useContext(MyContext);
  const [orderTypeStates, setOrderTypeStates] = useState(
    initialData || defaultOrderType
  );
  const [errors, setErrors] = useState({});
  const [isEditing, setIsEditing] = useState(!!initialData);

  useEffect(() => {
    if (initialData) {
      const sortAddonItems = _.orderBy(
        initialData.addon_items,
        ["position"],
        ["asc"]
      );
      setOrderTypeStates({ ...initialData, addon_items: sortAddonItems });
      setIsEditing(true);
    }
  }, [initialData]);

  const updateOrderTypeStates = (field, value) => {
    setOrderTypeStates((prev) => ({ ...prev, [field]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [field]: null }));
  };

  const resetOrderType = () => {
    setOrderTypeStates(defaultOrderType);
    setIsEditing(false);
    setErrors({});
  };

  const validateOrderType = (listOfOrderTypes) => {
    const newErrors = {};
    if (!orderTypeStates.name.trim()) {
      newErrors.name = langData.order_type_name_required;
    }
    if (!orderTypeStates.description.trim()) {
      newErrors.description = langData.vendor_desc_req;
    }
    const elementsIndex = listOfOrderTypes.findIndex(
      (indexElement) =>
        indexElement.name.toUpperCase() === orderTypeStates.name.toUpperCase()
    );
    if (elementsIndex !== -1 && !isEditing) {
      newErrors.name = `${orderTypeStates.name} already exist`;
    }
    if (
      elementsIndex !== -1 &&
      isEditing &&
      listOfOrderTypes[elementsIndex].id !== orderTypeStates.otype_id
    ) {
      newErrors.name = `${orderTypeStates.name} already exist`;
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  return {
    orderTypeStates,
    errors,
    setErrors,
    isEditing,
    updateOrderTypeStates,
    resetOrderType,
    validateOrderType,
  };
};

export default useOrderType;
