/* eslint-disable no-restricted-globals */
import { useState, useEffect, useContext } from "react";
import _ from "lodash";
import MyContext from "../Provider/MyContext";

const defaultModifierGroup = {
  addon_cat_name: "",
  is_mandatory: false,
  allow_multi_select: false,
  minimum_select: 0,
  maximum_select: 1,
  allow_multi_quantity: false,
  minimum_quantity: 1,
  maximum_quantity: 1,
  addon_items: [],
};

const useModifierGroup = (initialData = null) => {
  const { langData } = useContext(MyContext);
  const [modifierGroup, setModifierGroup] = useState(
    initialData || defaultModifierGroup
  );
  const [errors, setErrors] = useState({});
  const [isEditing, setIsEditing] = useState(!!initialData);

  useEffect(() => {
    if (initialData) {
      const sortAddonItems = _.orderBy(
        initialData.addon_items,
        ["position"],
        ["asc"]
      );
      setModifierGroup({ ...initialData, addon_items: sortAddonItems });
      setIsEditing(true);
    }
  }, [initialData]);

  useEffect(() => {
    if (!modifierGroup.allow_multi_select) {
      updateModifierGroup("minimum_select", 0);
      updateModifierGroup("maximum_select", 1);
    }
  }, [modifierGroup.allow_multi_select]);

  useEffect(() => {
    if (!modifierGroup.allow_multi_quantity) {
      updateModifierGroup("minimum_quantity", 1);
      updateModifierGroup("maximum_quantity", 1);
    }
  }, [modifierGroup.allow_multi_quantity]);

  useEffect(() => {
    if (modifierGroup.is_mandatory && modifierGroup.minimum_select === 0) {
      updateModifierGroup("minimum_select", 1);
    } else if (
      !modifierGroup.is_mandatory &&
      modifierGroup.minimum_select === 1
    ) {
      updateModifierGroup("minimum_select", 0);
    }
  }, [modifierGroup.is_mandatory]);
  const updateModifierGroup = (field, value) => {
    setModifierGroup((prev) => ({ ...prev, [field]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [field]: null }));
  };

  const updateAddonItem = (index, field, value) => {
    setModifierGroup((prev) => ({
      ...prev,
      addon_items: prev.addon_items.map((item, i) =>
        i === index ? { ...item, [field]: value } : item
      ),
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [`${field}_${index}`]: null,
    }));
  };

  const addAddonItem = () => {
    setModifierGroup((prev) => ({
      ...prev,
      addon_items: [
        ...prev.addon_items,
        {
          addon_item_name: "",
          position: prev.addon_items.length,
          base_price: null,
        },
      ],
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      addon_items: null,
    }));
  };

  const removeAddonItem = (index) => {
    const removeItem = modifierGroup.addon_items.filter((_, i) => i !== index);
    const reassignIndexes = removeItem.map((item, index) => ({
      ...item,
      position: index,
    }));
    setModifierGroup((prev) => ({
      ...prev,
      addon_items: reassignIndexes,
    }));
  };

  const resetModifierGroup = () => {
    setModifierGroup(defaultModifierGroup);
    setIsEditing(false);
    setErrors({});
  };

  const validateModifierGroup = () => {
    const newErrors = {};
    if (!modifierGroup.addon_cat_name.trim()) {
      newErrors.addon_cat_name = langData.modifier_group_name_required;
    }
    if (modifierGroup.addon_items.length === 0) {
      newErrors.addon_items = langData.please_add_atleast_one_modifier_item;
    }
    if (
      modifierGroup.addon_items.some((item) => !item.addon_item_name.trim())
    ) {
      modifierGroup.addon_items.forEach((item, index) => {
        if (!item.addon_item_name.trim()) {
          newErrors[`addon_item_name_${index}`] =
            langData.modifier_item_name_required;
        }
      });
    }
    if (modifierGroup.addon_items.some((item) => !item.base_price)) {
      modifierGroup.addon_items.forEach((item, index) => {
        if (item.base_price === null || isNaN(item.base_price)) {
          newErrors[`base_price_${index}`] = langData.base_price_required;
        }
      });
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  return {
    modifierGroup,
    errors,
    isEditing,
    updateModifierGroup,
    updateAddonItem,
    addAddonItem,
    removeAddonItem,
    resetModifierGroup,
    validateModifierGroup,
  };
};

export default useModifierGroup;
