import React, { useContext } from "react";
import { Grid } from "@material-ui/core";
import { AvenirBlackH4, H4 } from "../utils/text";
import MyContext from "../Provider/MyContext";
import { CustomCheckbox, ModifierCounter } from ".";

const ModifierMinMaxSelect = ({
  checked,
  checkBoxLabel,
  supportingTextLabel,
  initialCountOfMin,
  initialCountOFmax,
  minOfMin,
  maxOfMin,
  minOfMax,
  maxOfMax,
  onCheckboxChange,
  onMinChange,
  onMaxChange,
  multiSelectMax,
}) => {
  const { langData } = useContext(MyContext);

  const handleMinChange = (value) => {
    onMinChange(value);
  };

  const handleMaxChange = (value) => {
    onMaxChange(value);
  };

  const handleCheckboxChange = (e) => {
    onCheckboxChange(e.target.checked);
  };

  return (
    <Grid container direction='column' style={{ width: "100%" }}>
      <Grid item container alignItems='center'>
        <CustomCheckbox checked={checked} onChange={handleCheckboxChange} />
        <AvenirBlackH4 label={checkBoxLabel} style={{ marginRight: 20 }} />
      </Grid>
      <Grid
        item
        container
        direction='column'
        alignItems='flex-start'
        style={{ marginLeft: 30, width: "95%" }}>
        <Grid
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}>
          <H4
            label={supportingTextLabel}
            style={{ width: checked ? "60%" : "100%" }}
          />
          {checked && (
            <Grid
              container
              spacing={2}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexWrap: "nowrap",
                width: "40%",
              }}>
              <Grid style={{ width: "40%", marginRight: "5%" }}>
                <H4 label={langData.min} />
                <ModifierCounter
                  initialCount={initialCountOfMin}
                  onChange={handleMinChange}
                  max={maxOfMin}
                  min={minOfMin}
                  multiSelectMax={multiSelectMax}
                />
              </Grid>
              <Grid style={{ width: "40%" }}>
                <H4 label={langData.max} />
                <ModifierCounter
                  initialCount={initialCountOFmax}
                  onChange={handleMaxChange}
                  max={maxOfMax}
                  min={minOfMax}
                  multiSelectMax={multiSelectMax}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ModifierMinMaxSelect;
