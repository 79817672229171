import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import { useLDClient } from "launchdarkly-react-client-sdk";
import VerticalTabs from "../../../components/verticalTabs";
import MyContext from "../../../Provider/MyContext";
import NoPermissionPage from "../../../components/NoPermission";
import { FeatureKeys } from "../../../enums";
import Offers from "./Offers";
import Shifts from "./Shifts";
import Printers from "./Printers";
import Kitchens from "./KitchenCreation";
import DeliveryOptions from "./More";
import Reports from "./Reports";
import KitchenTickets from "./KitchenTickets";

const General = () => {
  const Context = useContext(MyContext);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const userPermissions = useSelector(
    (state) => state.loginReducer.userPermissions
  );
  const Tabs = [
    {
      name: Context.langData.discounts,
      id: 0,
    },
    {
      name: Context.langData.shifts,
      id: 1,
    },
    {
      name: Context.langData.printers,
      id: 2,
    },
    {
      name: Context.langData.kitchen_creation,
      id: 3,
    },
    {
      name: Context.langData.delivery_options,
      id: 4,
    },
    {
      name: Context.langData.Reports,
      id: 5,
    },
  ];

  const featureFlagClient = useLDClient();
  if (featureFlagClient) {
    const flagValue = featureFlagClient.variation(
      FeatureKeys.KitchenTicket,
      false
    );
    if (flagValue) {
      Tabs.push({ name: Context.langData.kitchen_tickets, id: 6 });
    }
  }

  const indexCallback = (value) => {
    setSelectedTabIndex(value);
  };
  const renderView = () => {
    switch (selectedTabIndex) {
      case 0:
        return isPermissionCheck("offers") ? (
          <Offers data-qaid='ordertypeList_index' />
        ) : (
          <NoPermissionPage data-qaid='ordertypeList_nopermission' />
        );
      case 1:
        return isPermissionCheck("shifts") ? (
          <Shifts data-qaid='ordertypeList_index' />
        ) : (
          <NoPermissionPage data-qaid='ordertypeList_nopermission' />
        );
      case 2:
        return isPermissionCheck("printers") ? (
          <Printers data-qaid='ordertypeList_index' />
        ) : (
          <NoPermissionPage data-qaid='ordertypeList_nopermission' />
        );
      case 3:
        return isPermissionCheck("kitchen") ? (
          <Kitchens data-qaid='ordertypeList_index' />
        ) : (
          <NoPermissionPage data-qaid='ordertypeList_nopermission' />
        );

      case 4:
        return isPermissionCheck("delivery_options") ? (
          <DeliveryOptions data-qaid='ordertypeList_index' />
        ) : (
          <NoPermissionPage data-qaid='ordertypeList_nopermission' />
        );

      case 5:
        return isPermissionCheck("End_of_Day_Setting") ? (
          <Reports data-qaid='reports_nopermission' />
        ) : (
          <NoPermissionPage data-qaid='ordertypeList_nopermission' />
        );

      case 6:
        return isPermissionCheck("Kitchen_Guest_Receipt_Setting") ? (
          <KitchenTickets data-qaid='kitchen_tickets_nopermission' />
        ) : (
          <NoPermissionPage data-qaid='ordertypeList_nopermission' />
        );

      default:
        return null;
    }
  };
  const isPermissionCheck = (val) => {
    let isPermission = false;
    const obj = userPermissions.filter((e) => e.key === val);
    if (obj.length > 0) {
      if (obj[0].status) {
        isPermission = true;
      }
    }

    return isPermission;
  };
  return (
    <div
      data-qaid='ordertypes_index_div'
      style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 20 }}>
      <Grid
        data-qaid='menu_grid'
        container
        direction='row'
        style={{ borderTop: "1px solid #EEEEEE" }}>
        <Grid
          data-qaid='menu_tabs'
          item
          xs={12}
          lg={2}
          style={{ backgroundColor: "#FAFAFA" }}>
          <VerticalTabs
            data-qaid='menu_options_vtabs'
            tabData={Tabs}
            indexCallback={indexCallback}
            style={{ marginTop: 24 }}
          />
        </Grid>
        <Grid
          data-qaid='menu_options_addbutton'
          item
          xs={12}
          lg={10}
          style={{ marginTop: 10 }}>
          {renderView()}
        </Grid>
      </Grid>
    </div>
  );
};

export default General;
