import * as React from "react";

const CloseCross = ({ ...props }) => (
  <svg
    width='10'
    height='10'
    viewBox='0 0 10 10'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0.16683 0.16683C0.389271 -0.0556101 0.749918 -0.0556101 0.972358 0.16683L5.00001 4.19447L9.02764 0.166847C9.25008 -0.0555936 9.61073 -0.0555936 9.83317 0.166847C10.0556 0.389287 10.0556 0.749934 9.83317 0.972374L5.80554 5L9.83317 9.02763C10.0556 9.25007 10.0556 9.61071 9.83317 9.83316C9.61073 10.0556 9.25008 10.0556 9.02764 9.83316L5.00001 5.80553L0.972359 9.83317C0.749919 10.0556 0.389272 10.0556 0.166831 9.83317C-0.055609 9.61073 -0.055609 9.25008 0.166831 9.02764L4.19448 5L0.16683 0.972357C-0.0556101 0.749917 -0.0556101 0.38927 0.16683 0.16683Z'
      fill='#004C60'
    />
  </svg>
);

export default CloseCross;
