import { useState } from "react";

const useComponentStates = (initialState = {}) => {
  const [componentStates, setComponentStates] = useState(initialState);

  const updateComponentStates = (field, value) => {
    setComponentStates((prevState) => ({ ...prevState, [field]: value }));
  };
  return { componentStates, updateComponentStates };
};
export default useComponentStates;
