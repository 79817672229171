import React, { useState, useEffect, useCallback, useContext } from "react";
import { Hidden, Popover, Button, Grid, IconButton } from "@material-ui/core";

import {
  addDays,
  startOfWeek,
  endOfWeek,
  addWeeks,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfYear,
} from "date-fns";
import DateFnsAdapter from "@material-ui/pickers/adapter/date-fns";
import moment from "moment-timezone";
import {
  MobileDateRangePicker,
  LocalizationProvider,
} from "@material-ui/pickers";
import { H4, T } from "../utils/text";
import MyContext from "../Provider/MyContext";
import { dateFormat } from "../utils/util";
import DateRangePicker from "../mui-drp";
import { CalenderIcon, CircleClose } from "./SVG";

const date = new Date();
const Daterange = ({
  onDateChange,
  refresh = null,
  initialValue = null,
  initialRange = null,
  refreshWithValues = null,
}) => {
  const tz = moment.tz.guess();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const Context = useContext(MyContext);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const dateRanges = useCallback(() => {
    const range = [
      {
        label: Context.langData.today,
        startDate: date,
        endDate: date,
      },
      {
        label: Context.langData.yesterday,
        startDate: addDays(date, -1),
        endDate: addDays(date, -1),
      },
      {
        label: Context.langData.this_week,
        startDate: startOfWeek(date),
        endDate: endOfWeek(date),
      },
      {
        label: Context.langData.last_week,
        startDate: startOfWeek(addWeeks(date, -1)),
        endDate: endOfWeek(addWeeks(date, -1)),
      },
      {
        label: Context.langData.last_7days,
        startDate: addWeeks(date, -1),
        endDate: date,
      },
      {
        label: Context.langData.this_month,
        startDate: startOfMonth(date),
        endDate: endOfMonth(date),
      },
      {
        label: Context.langData.last_month,
        startDate: startOfMonth(addMonths(date, -1)),
        endDate: endOfMonth(addMonths(date, -1)),
      },
      {
        label: Context.langData.this_year,
        startDate: startOfYear(date),
        endDate: date,
      },
    ];
    return range;
  }, []);
  const [dateRange, setDateRange] = useState(
    initialRange || { label: "Today", startDate: date, endDate: date }
  );
  const [value, setValue] = React.useState(
    initialValue ? [...initialValue] : [date, date]
  );

  useEffect(() => {
    if (refresh) {
      setValue([date, date]);
      setDateRange({ label: "Today", startDate: date, endDate: date });
    } else if (refreshWithValues && initialValue && initialRange) {
      setValue([...initialValue]);
      setDateRange(initialRange);
    }
  }, [refresh, refreshWithValues, initialValue, initialRange]);

  useEffect(() => {
    moment.tz.setDefault(tz);
    if (value.length) {
      onDateChange({
        start_date: moment(value[0], tz).startOf("day").unix(),
        end_date: moment(value[1], tz).endOf("day").unix(),
        startDate: moment(value[0], tz).startOf("day").format("YYYY-MM-DD"),
        endDate: moment(value[1], tz).endOf("day").format("YYYY-MM-DD"),
      });
    }
  }, [value, onDateChange]);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const toggle = () => {
    if (open) {
      handleClose();
    } else {
      handleClick();
    }
  };
  const setDates = () => {
    if (dateRange.endDate) {
      setValue([dateRange.startDate, dateRange.endDate]);
      toggle();
    }
  };

  const dt = `${dateFormat(value[0]) || "Start"} - ${
    dateFormat(value[1]) || "End"
  }`;

  const handleCloseButton = () => {
    toggle();
    setDateRange({ startDate: value[0], endDate: value[1] });
  };
  return (
    <>
      <Hidden data-qaid='dateRange_hidden' smUp>
        <LocalizationProvider
          data-qaid='dateRange_localization_provider'
          dateAdapter={DateFnsAdapter}>
          <MobileDateRangePicker
            data-qaid='dateRange_mobile_date_rangePicker'
            value={value}
            onAccept={(val) => setValue(val)}
            onChange={() => {}}
            renderInput={(startProps, endProps) => (
              <Button
                data-qaid='dateRange_button'
                endIcon={<CalenderIcon />}
                aria-describedby={id}
                variant='outlined'
                color='primary'
                onClick={startProps.inputProps.onClick}>
                {moment(startProps.inputProps.value, tz).format(
                  "MMM DD, YYYY"
                ) || "Start"}{" "}
                -{" "}
                {moment(endProps.inputProps.value, tz).format("MMM DD, YYYY") ||
                  "End"}
              </Button>
            )}
          />
        </LocalizationProvider>
      </Hidden>
      <Hidden xsDown data-qaid='dateRange_hidden_xs'>
        <Button
          data-qaid='dateRange_xs_button'
          startIcon={<CalenderIcon />}
          style={{ height: 56, borderRadius: 4, borderColor: "#707272" }}
          aria-describedby={id}
          variant='outlined'
          color='primary'
          onClick={handleClick}>
          <div
            style={{
              flexDirection: "row",
              alignItems: "center",
              display: "flex",
            }}>
            <H4 label={dt} style={{ fontWeight: 300 }} />
          </div>
        </Button>
        <Popover
          data-qaid='dateRange_popover'
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}>
          <DateRangePicker
            data-qaid='dateRange_xs_date_range_picker'
            open={open}
            toggle={toggle}
            wrapperClassName='date_range_picker'
            definedRanges={dateRanges()}
            initialDateRange={dateRange}
            maxDate={new Date()}
            nextDays={false}
            reset={false}
            onChange={(range) => setDateRange(range)}
          />
          <Grid
            container
            data-qaid='dateRange_button_div'
            style={{ display: "flex", flexDirection: "row", padding: 20 }}>
            <Grid item xs={12} sm={6}>
              <Grid container direction='row' alignItems='center'>
                <T
                  style={{ marginRight: 10 }}
                  label={`${dateFormat(dateRange.startDate)} - ${dateFormat(
                    dateRange.endDate
                  )} `}
                />
                <IconButton
                  onClick={() =>
                    setDateRange({
                      label: "Today",
                      startDate: date,
                      endDate: date,
                    })
                  }>
                  <CircleClose />
                </IconButton>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid
                container
                data-qaid='dateRange_button_div'
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}>
                <Button
                  data-qaid='dateRange_close_button_grid'
                  variant='contained'
                  style={{
                    backgroundColor: "#FFF",
                    marginRight: 10,
                    height: 40,
                    width: 103,
                    border: "2px solid #051D33",
                  }}
                  onClick={handleCloseButton}>
                  <H4
                    data-qaid='dateRange_close_button_label'
                    label={Context.langData.close}
                  />
                </Button>
                <Button
                  data-qaid='dateRange_apply_button'
                  variant='contained'
                  style={{
                    backgroundColor: dateRange.endDate ? "#FFB600" : "#707272",
                    height: 40,
                    width: 98,
                  }}
                  onClick={setDates}>
                  <H4
                    data-qaid='dateRange_apply_button_label'
                    label={Context.langData.apply}
                  />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Popover>
      </Hidden>
    </>
  );
};
export default React.memo(Daterange);
